import { Container, Form,Button, Alert, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";
import { UserContext } from "../../Utils/userContext";
import { useEffect } from "react";
import { api } from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh} from "@fortawesome/free-solid-svg-icons";


function LoginPage(){
    const [email, setEmail] = useState("");
    const [mdp, setMdp] = useState("");
    const [message, setMessage] = useState("");
    let navigate = useNavigate();

    const {userAuth, setUserAuth} = useContext(UserContext);

    useEffect(() => {
        document.title = "Se connecter | UAC";  
      }, []);

    const login = async(e) => {
        e.preventDefault();

        try {
            const response = await api.post('/login', { email: email, password : mdp });
            const user = response.data;
            setUserAuth(user);
            
            
            // Stockez le jeton JWT côté client (par exemple, dans localStorage)
            localStorage.setItem('token', user.token);
            localStorage.setItem('authUser', JSON.stringify(user));


            setMessage({error: false, msg: "Connecté en tant que administrateur"});
        
          } catch (error) {
            
            setMessage({error: true, msg: error.response.data});
          }
        
        setEmail("");
        setMdp("");
    }

    const logout = () => {

        setUserAuth(null);
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
        setMessage({error: false, msg: "Déconnecté !"});
    }

    return(
    <Container>
        <Row className='py-3 text-center'>
            <h1 className="titleh1">Connexion administrateur sur le site</h1>
        </Row>
        <Row className="">
            <Container>
                <Row>
                {message?.msg && (
                <Alert variant={ message?.error ? "danger" : "success"} dismissible onClose={ () => setMessage("")}>
                    {message?.msg}
                </Alert>)
                }
                </Row>


                
                <Row  className="mx-5">
                <Form onSubmit={login}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} 
                    onChange={(event) => {setEmail(event.target.value)}} />
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control type="password" placeholder="Enter mot de passe" value={mdp} 
                    onChange={(event) => {setMdp(event.target.value)}} />
                <p className="linkRGPD" onClick={() => { navigate(`/resetPassword`)}}>Mot de passe oublié ?</p>
                <Col className="mt-2">
                <Button variant="primary" type="submit">
                Se connecter
                </Button>
                {userAuth && 
                <Button variant="secondary" onClick={logout}>Se déconnecter</Button>}
                </Col>
                </Form>
                </Row>
                
            </Container>
        </Row>
        <Row>
            <Row className='py-3 text-center'>
                <h1 className="titleh1">Gestion du club</h1>
            </Row>
            <Row className='py-3 text-center'>
                <Col className="mt-2">
                    <Button variant="primary" href="https://club.uachatelineau.be/">
                        <FontAwesomeIcon icon={faTh}></FontAwesomeIcon> Gestion du club</Button>
                </Col>
            </Row>
        </Row>
    </Container>
)}

export default LoginPage;