import { Container, Row, Card, Col, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import { getPerformanceString, getInitial } from "../../Utils/utilis";
import { useNavigate } from "react-router-dom";
import { api, catchErrorAPI } from "../../api/apiConfig";

function LastResult(){

    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [performances, setPerformances] = useState([]);

    useEffect(() => {
        const fetch = async () => {
          try{
            const response = await api.get('/performances/latest');

            let perfStringTab = response.data;
            perfStringTab.map((perf, index) => {
              let perfString = "";
              perfString = getPerformanceString(perf.performance, perf.idDiscipline.type);
              perfStringTab[index].perfString = perfString;
              
            });
            setPerformances(perfStringTab);
            setLoading(false);
          }catch(err){
            catchErrorAPI(err);
          }
        }
        fetch();

    

  
      }, [])


    return(
    <Container className="bloc">
        <Row className="text-center">
              <h1 className="titleHomePage"><span className="jaune"><FontAwesomeIcon icon={faFlag}/> Derniers résultats</span></h1>
            </Row> 
            <Row xs={2} md={3} lg={4}>
                {loading &&
                    [...Array(11)].map(() => {
                        return(
                            <Col className='pt-3 d-flex flex-wrap'>
                                <Card className="flex-grow-1">
                                    <Card.Body>
                                        <Card.Title className="cardTitle" >Chargement
                                        </Card.Title>
                                        <Card.Text className="cardText">
                                            ....
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }

            {!loading &&
                performances.map(perf => {
                  return(
                    <Col className='pt-3 d-flex flex-wrap' key={perf.performance}>
                          <Card className="flex-grow-1">
                          <Card.Body>
                            <Card.Title className="cardTitle" onClick={() => {navigate(`/athlete/${perf.idAthlete._id}`)}}>{getInitial(perf.idAthlete.prenom, perf.idAthlete.nom)} <FontAwesomeIcon icon={faAnglesRight} className="iconTransparence"/>
                            </Card.Title>
                            <Card.Text className="cardText">
                                {perf.idDiscipline.label} {perf.performance === "" ? `` : `: ${getPerformanceString(perf.performance, perf.idDiscipline.type)}` }
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </Col>
                  )
                })
               }
                <Col className='pt-3 d-flex flex-wrap' key="pub">
                    <Card className="flex-grow-1 cardYellow">
                        <Card.Body onClick={() => {navigate(`/competitions`)}}>
                            <Card.Title className="cardTitle" >Tous les résultats ! <FontAwesomeIcon icon={faAnglesRight} className="iconTransparence"/>
                            </Card.Title>
                            <Card.Text className="cardText">
                                <u>En cliquant ici </u>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    </Container>
)}

export default LastResult;