import { Container, Row, Table, Button, Card, Badge, Col, Spinner } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import AddPerfComponent from "./AddPerfComponent";
import {getPerformanceString, getButtonColor, getSpeed, getFirstCapitalString, getWindSpan} from "../../Utils/utilis";
import { UserContext } from "../../Utils/userContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { api, catchErrorAPI } from "../../api/apiConfig";


function PerfComponent({ idAthlete, athlete, selectedSeason }) {
    const [performances, setPerformances] = useState([]);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);

    const { userAuth, setUserAuth } = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        fetchPerfByAthlete();
    }, [idAthlete, selectedSeason])

    const fetchPerfByAthlete = async () => {
        try {
            let response;

            if(selectedSeason === null){
                response = await api.get(`/performances/currentSeason/athlete/${idAthlete}`);
            }else{
                response = await api.get(`/performances/athlete/${idAthlete}/${selectedSeason}`);
            }
            
            const items = response.data.performances;

            items.map((doc, index) => {
                let perfString = "";

                perfString = getPerformanceString(doc.performance, doc.idDiscipline.type)

                items[index].perfString = perfString;

            });

            setPerformances(items);
            setRecords(response.data.records);

            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }




    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>;
    }

    const deletePerf = async (id) => {
        const apidelete = async () => {
            try {
                await api.delete(`/performances/${id}`);
                await fetchPerfByAthlete();
            } catch (err) {
                catchErrorAPI(err);
            }
        }
        apidelete();
    };



    return (
        <Container>
            <Row>
                <Col className="px-0">
                    <Container className="bloc">
                        <Row>
                            <Col>
                                <h2 className="titleBloc"><FontAwesomeIcon icon={faBolt} className="icon" />Records saison {selectedSeason}</h2>
                            </Col>
                        </Row>
                        <Row xs={2} md={3} lg={4}>
                            {
                                records.map(rec => {
                                    return (
                                        <Col className='pt-3 d-flex flex-wrap' key={rec.discipline._id} >
                                            <Card className="flex-grow-1">
                                                <Card.Body>
                                                    <Card.Title>{getFirstCapitalString(rec.discipline.label)} &nbsp;
                                                        <Badge bg={getButtonColor(rec.discipline.saison)}>{getFirstCapitalString(rec.discipline.saison)}</Badge></Card.Title>
                                                    <Card.Text>
                                                        <b>{getPerformanceString(rec.performance.performance, rec.discipline.type)}</b> {rec.discipline.metre ? <div className="kmh">{"(" + getSpeed(rec.discipline.metre,rec.performance.performance) + " km/h)"}</div> : ""}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row >
                <Col className="px-0">
                    <Container className="bloc">
                        <Row className='pt-3'>
                            <h2 className="titleBloc"><FontAwesomeIcon icon={faCheckCircle} className="icon" />Résultats saison {selectedSeason}</h2>
                        </Row>

                        {/*
                        {userAuth &&
                            <Row className="bloc">
                                <AddPerfComponent athlete={athlete} fetchPerfByAthlete={fetchPerfByAthlete} />
                            </Row>
                        }
                        */}

                        <Row className='pt-3 table-responsive'>
                            <Col>
                                <Table bordered hover size="sm" className="tablePerf" >
                                    <thead>
                                        <tr>
                                            <th>Compétiton</th>
                                            <th>Epreuve</th>
                                            <th>Perf</th>
                                            {userAuth && <th>Action</th>}

                                        </tr>
                                    </thead>

                                    <tbody>

                                    {performances.length === 0 && <tr>
                                        <td colSpan={3} className="center-table">
                                            Pas de résultat
                                        </td>

                                    </tr>
                                        }

                                        {
                                            performances.map((performance, index) => {

                                                let rowClass = performance.idCompetition.isChampionship ? 'championship-row' : '';

                                                return (
                                                    <tr key={performance._id}>
                                                        <td className={rowClass}>
                                                            {new Date(performance.idCompetition.date).toLocaleDateString("fr-FR")}
                                                            <br /> {performance.idCompetition.nom} <span className="pl-2" />
                                                            <Badge bg={getButtonColor(performance.idCompetition.saison)}>{performance.idCompetition.saison}</Badge>
                                                        </td>
                                                        <td className={rowClass}>{performance.idDiscipline.label}</td>
                                                        <td className={rowClass}>
                                                            {performance.perfString}
                                                            <br /> {(performance.windSpeed && performance.windSpeed != 0) && <span className={getWindSpan(performance.windSpeed)}>({performance.windSpeed} m/s)</span> }
                                                        </td>
                                                        {userAuth &&
                                                            <td  className={rowClass}>
                                                                <Button variant="danger" size="sm" onClick={() => { deletePerf(performance._id) }}>
                                                                    Supprimer</Button>
                                                            </td>}
                                                    </tr>


                                                )
                                            }

                                            )}

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default PerfComponent;