
import {Container, Row, Button, Alert, Spinner, Col, Table, Modal} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { api, catchErrorAPI } from "../../api/apiConfig";
import {useNavigate} from "react-router-dom";
import {ErrorMessage, Field, Formik, Form} from "formik";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {faXmark} from "@fortawesome/free-solid-svg-icons";


function GestionDiscipline() {

    let navigate = useNavigate();


    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    let[disciplines, setDisciplines] = useState();
    const [disSelected, setDisSelected] = useState(null);
    const [initialValues, setInitialValues] = useState({
        label: '',
        metre: ''
    });

    const validationSchema = Yup.object().shape({
        label: Yup.string().required('Un label est requis'),
    });

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setInitialValues({
            label: '',
            metre: ''
        });
        setShowModal(false);
    }

    const handleShowModal = (dis) => {
        setDisSelected(dis);
        setInitialValues({
            label : dis.label,
            metre: dis.metre
        })
        setShowModal(true);
    }


    useEffect(() => {

        fetchDisciplines();
    }, []);

    const fetchDisciplines = async () => {

        try{
            const response = await api.get('/disciplines');
            setDisciplines(response.data);
            setLoading(false);
        }catch(err){
            catchErrorAPI(err);
        }
    }

    const handleUp = async (epreuve) => {

        try{
            await api.put(`/discipline/up/${epreuve._id}`);
            fetchDisciplines()
        }catch(err){
            catchErrorAPI(err);
        }
    }

    const handleDown = async (epreuve) => {

        try{
            await api.put(`/discipline/down/${epreuve._id}`);
            fetchDisciplines()
        }catch(err){
            catchErrorAPI(err);
        }
    }

    const editDiscipline = async (values) => {

            const putNews = async () => {
                try {
                    await api.put(`/discipline/${disSelected._id}`, {
                        label : values.label,
                        metre : parseInt(values.metre)
                    });
                    fetchDisciplines();
                    handleCloseModal();
                    setMessage({ error: false, msg: "Discipline modifiée !" });
                } catch (err) {
                    setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                    catchErrorAPI(err);
                }
            }
            putNews();

    }





    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }

    return (
        <Container className="mt-2">
            {message?.msg && (
                <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                    {message?.msg}
                </Alert>)
            }
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Label</th>
                            <th>Saison</th>
                            <th>Metre</th>
                            <th>Nb performances</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            disciplines.map((d => {
                                return (
                                    <tr key={d._id}>
                                        <td>{d.sequence} - {d.label}</td>
                                        <td>{d.saison}</td>
                                        <td>{d.metre}</td>
                                        <td>{d.nbPerf}</td>
                                        <td>
                                            <Button onClick={()=> handleShowModal(d)}>Modifier</Button>
                                            <Button onClick={()=> handleUp(d)}>up</Button>
                                            <Button onClick={()=> handleDown(d)}>down</Button>
                                        </td>
                                    </tr>
                                )
                            }))
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>


            <Modal show={showModal} onHide={handleCloseModal} backdrop="static"
                   keyboard={false} fullscreen={false} >
                <Modal.Header className='headerModal'>
                    <Modal.Title className='titleModal'>
                        Modifier discipline
                    </Modal.Title>
                    <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <Container>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log('test')
                                editDiscipline(values);
                            }}
                        >
                            {({ values, errors, touched }) => (
                                <Form>
                                    <label htmlFor="label">Label</label> <br />
                                    <Field id="label" name="label" className="fieldTitleNews" /><br />

                                    <label htmlFor="metre">Metres</label> <br />
                                    <Field id="metre" type="number" name="metre" className="fieldTitleNews" /><br />


                                    <Button variant="success" type="submit" className="mt-3" >
                                        Modifier
                                    </Button>

                                    <div>
                                        <p><ErrorMessage name="label" /></p>
                                    </div>

                                </Form>)}
                        </Formik>



                    </Container>
                </Modal.Body>
            </Modal>



        </Container>
    )
}

export default GestionDiscipline;