import {Container, Row, Table, Button, Card, Badge, Col, Spinner, Alert, Modal} from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import AddPerfComponent from "./AddPerfComponent";
import {getPerformanceString, getButtonColor, getSpeed, getFirstCapitalString} from "../../Utils/utilis";
import { UserContext } from "../../Utils/userContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBolt, faXmark} from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { api, catchErrorAPI } from "../../api/apiConfig";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";


function FormTransferDiscipline({ oldDiscipineId, fetchPerformances }) {
    const [disciplines, setDiscipline] = useState([]);
    const [loading, setLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        oldDisciplineId: '',
        newDisciplineId: '',
    });

    const validationSchema = Yup.object().shape({
        oldDisciplineId: Yup.string().required('Un oldDisciplineId est requis'),
        newDisciplineId: Yup.string().required('Un newDisciplineId est requis')
    });

    const [message, setMessage] = useState("");


    useEffect(() => {
        fetchDiscipline();
        if(oldDiscipineId){
            initialValues.oldDisciplineId = oldDiscipineId;
        }
    }, [oldDiscipineId])

    const fetchDiscipline = async () => {
        try {
            let response;
            response = await api.get(`/disciplines`);
            setDiscipline(response.data)
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const transfertDiscipline = async(values) => {
        try {
            console.log(values)
            await api.put(`/transfertDiscipline`, {
                oldDisciplineId: values.oldDisciplineId,
                newDisciplineId : values.newDisciplineId
            });
            setMessage({ error: false, msg: "Tansfère réalisé !" });
            fetchPerformances();

        } catch (err) {
            setMessage({ error: true, msg: err.msg });
            catchErrorAPI(err);
        }
    }


    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>;
    }


    return (
        <Container>
            <Row>
                {message?.msg && (
                    <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                        {message?.msg}
                    </Alert>)
                }
            </Row>
            <Row>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        transfertDiscipline(values);
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form>

                            <label htmlFor="oldDisciplineId">Ancienne discipline à transférer</label><br />
                            <Field name="oldDisciplineId" as="select">
                                <option value="" disabled> -- Ancienne -- </option>
                                {
                                    disciplines.map((dis) => (
                                        <option value={dis._id} key={dis._id}>{dis.label}  - {dis?.saison}</option>
                                    ))
                                }
                            </Field> <br />

                            <label htmlFor="newDisciplineId">Nouvelle discipline</label><br />
                            <Field name="newDisciplineId" as="select">
                                <option value="" disabled> -- Nouvelle -- </option>
                                {
                                    disciplines.map((dis) => (
                                        <option value={dis._id} key={dis._id}>{dis.label} - {dis?.saison}</option>
                                    ))
                                }
                            </Field>

                            <div>
                                <p><ErrorMessage name="oldDisciplineId" /></p>
                                <p><ErrorMessage name="newDisciplineId" /></p>
                            </div>

                            <Button className="mt-2" variant="primary" type="submit">Transférer</Button>

                        </Form>)}
                </Formik>
            </Row>
        </Container>
    )
}

export default FormTransferDiscipline;