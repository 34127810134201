import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FileComponent from '../../components/FileComponent'

function StadiumPage() {
  return (
    <Container>
      <Row className="text-center pt-3">
        <Col>
          <h1 className="titleh1"><span className="jauneTitre">Informations pratiques</span></h1>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='mx-2 bloc'>
          <Container >
            <Row>
              <Col>
                <h3 className="titleh1"><span className="jaune">Stade</span></h3> <br />
              </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
              <Col>
                <h4><span className="jaune">Adresse</span></h4>
                <p>
                  Stade du Taillis-Pré <br />
                  Rue de l'Église à 6200 Châtelineau
                </p>
                <div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9055.204843363013!2d4.511855457981329!3d50.440525581393345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c228727255f96f%3A0x628b98aaef895070!2sStade%20du%20Taillis-Pr%C3%A9!5e0!3m2!1sfr!2sbe!4v1688835857396!5m2!1sfr!2sbe" width="100%" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

              </Col>
              <Col>
                <h4><span className="jaune">Infrastructures</span></h4>
                <ul className='listInfra'>
                  <li>Piste d'athlétisme 4/6 couloirs</li>
                  <li>Parcours de cross</li>
                  <li>À proximité du bois de Soleilmont</li>
                  <li>Vestiaires</li>
                  <li>Douches</li>
                  <li>Club house </li>
                </ul>
              </Col>
            </Row>
          </Container>

        </Col>
      </Row>
      <Row>
        <Col className='mx-2 bloc'>
          <h3 className="titleh1"><span className="jaune">Contactez-nous</span></h3><br />
          Contactez-nous à l'adresse mail <b>info@uachatelineau.be</b> ou venez nous rencontrer directement au club.
        </Col>
      </Row>
      <Row>
        <Col className='mx-2 bloc'>
          <h3 className="titleh1"><span className="jaune">Documents et liens utiles</span></h3>
          <FileComponent></FileComponent>
        </Col>

      </Row>
    </Container>
  )
}

export default StadiumPage