import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";


import { useEffect } from "react";
import { api } from "../../api/apiConfig";


function RegisterPage() {
    const [email, setEmail] = useState("");
    const [mdp, setMdp] = useState("");
    const [mdp2, setMdp2] = useState("");
    const [message, setMessage] = useState("");
    const [code, setCode] = useState("");

    //const {userAuth, setUserAuth} = useContext(UserContext);

    useEffect(() => {
        document.title = "Enregistrement | UAC";

        const urlParams = new URLSearchParams(window.location.search);
        setEmail(urlParams.get('email'));
        setCode(urlParams.get('code'));
        console.log(code);

    }, []);

    const register = async (e) => {
        e.preventDefault();

        if (mdp != mdp2) {
            setMessage({ error: true, msg: "Entrez 2 fois le même mot de passe" });
        } else {

            try {
                await api.post(`/register/`, { email: email, password: mdp, code: code });

                setMessage({ error: false, msg: "Vous êtes bien enregistrer, vous pouvez vous connecter grâce au bouton en haut à droite" })

            } catch (error) {

                setMessage({ error: true, msg: error.response.data });
            }
        }

        setMdp("");
        setMdp2("");
    }



    return (
        <Container>
            <Row className='py-3 text-center'>
                <h1 className="titleh1">Enregistrer son compte</h1>
            </Row>
            <Row className="">
                <Container>
                    <Row>
                        {message?.msg && (
                            <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                                {message?.msg}
                            </Alert>)
                        }
                    </Row>

                    <Row className="mx-5">
                        <Form onSubmit={register}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email}
                                disabled />
                            <Form.Label>Nouveau mot de passe (à retenir)</Form.Label>
                            <Form.Control type="password" placeholder="Enter mot de passe" value={mdp}
                                onChange={(event) => { setMdp(event.target.value) }} />
                            <Form.Label>Confirmer le mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Confirmer" value={mdp2}
                                onChange={(event) => { setMdp2(event.target.value) }} />
                            <Col className="mt-2">
                                <Button variant="primary" type="submit">
                                    S'enregistrer
                                </Button>

                            </Col>
                        </Form>
                    </Row>

                </Container>
            </Row>
        </Container>
    )
}

export default RegisterPage;