import { Button, Container, Row, Form, ListGroup, ListGroupItem, Col, Spinner, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api, catchErrorAPI } from "../../api/apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";


function GestionCommunicationPage() {
    

    const [newCommunication, setNewCom] = useState("");
    const [informations, setInformations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Admin Informations | UAC";
        fetchNews();
    }, []);

    const fetchNews = async () => {
        
        try{
          const response = await api.get('/info');
          setInformations(response.data);
          setLoading(false);
        }catch(err){
          catchErrorAPI(err);
        }
      }

    const createCommunication = async () => {
        const postInfo = async () => {
            try{
              await api.post(`/info`,{ label: newCommunication, position : 99 });
              fetchNews();
              setNewCom("");
            }catch(err){
              catchErrorAPI(err);
            }
          }
          postInfo();
    };
    const deleteCommunication = async (id) => {
        const deleteCommunication = async () => {
            try{
              await api.delete(`/info/${id}`);
              fetchNews();
            }catch(err){
              catchErrorAPI(err);
            }
          }
          deleteCommunication();
          
    };

    const handleUp = async(element , index) => {
        if (index > 0) {
            element.position = element.position -1
            putInfo(element);
        }
    }

    const putInfo = async (item) => {
        try{
          await api.put(`/info/${item._id}`,item);
            fetchNews();
        }catch(err){
          catchErrorAPI(err);
        }
      }

    if (loading) {
        return (
          <Container className="bloc">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement...</span>
            </Spinner>
          </Container>
        )
      }


    return (
        <Container>
            <Row className="mb-3">
                <Col><h2>Gestion des communications</h2></Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ListGroup>
                        {
                            informations.map(((information, index) => {
                                return (
                                    <Card>
                                        <Card.Body>
                                        {information.label} 
                                        </Card.Body>
                                        
                                        
                                        <br/>
                                        <FontAwesomeIcon icon={faCaretUp} onClick={() => handleUp(information, index)} /> {`  `} 
                                        <Button variant="danger" size="sm" onClick={() => { deleteCommunication(information._id) }}>Supprimer</Button>
                                    </Card>
                                )
                            }))
                        }
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className="mb-3">Ajouter une communication</h2>

                    <Form.Control as="textarea"  onChange={(event) => { setNewCom(event.target.value) }} value={newCommunication} />
                    <Button className="mt-2" type="submit" onClick={createCommunication}>Ajouter</Button>
                </Col>

            </Row>

        </Container>
    )
}

export default GestionCommunicationPage;