import React, { useEffect, useState } from 'react'

import {
  useNavigate,
  useParams
} from "react-router-dom";
import { Container, Col, Row, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { api, catchErrorAPI } from '../../api/apiConfig';

function OneNewsPage() {
  const { idActualite } = useParams();
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const [news, setNews] = useState("");




  useEffect(() => {
    document.title = "Actualités | UAC";

    const fetchNews = async () => {
      try{
        const response = await api.get(`/news/${idActualite}`);
        setNews(response.data);
        setLoading(false);
      }catch(err){
        catchErrorAPI(err);
      }
    }
    fetchNews();
  }, [idActualite]);



  if (loading) {
    return (
      <Container className="bloc">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </Container>
    )
  }


  return (
    <Container>
      <Helmet>
        <meta property="og:url" content="https://www.your-domain.com/your-page.html" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="UAC Chatelineau" />
        <meta property="og:description" content="Your description" />
        <meta property="og:image" content="https://www.your-domain.com/path/image.jpg" />
      </Helmet>
      <Row className='py-3 d-flex align-items-center justify-content-between' xs={3} md={3} lg={3}>
        <Col xs={1} md={2} lg={2} className="text-left">
          <span className="returnButton" onClick={() => { navigate("/actualites") }}>
            <FontAwesomeIcon id="faChevronLeft" icon={faArrowLeft} />
            <span class="d-none d-md-inline"> Actualités</span>
          </span>
        </Col>
        <Col xs={10} md={10} lg={10} className="text-center"><h1 className="titleh1"><span className="jaune">{news.titre}</span></h1></Col>
        <Col xs={1} md={2} lg={2}></Col>
      </Row>
      <Row >
        <Col className='pt-3'>
          <Container className='bloc p-4'>
            <Row>
              {new Date(news.dateCreation).toLocaleDateString("fr-FR")} - {news.type} - {news.auteur}
            </Row>
            <Row><hr /></Row>
            <Row className='mb-3' style={{ whiteSpace: "pre-line" }}>{news.contenu}</Row>

            {news.urlLink && <Row className='m-4 px-3'><Button className='buttonNews' href={news.urlLink} target="_blank">{news.labelLink ? news.labelLink : 'Lien'}</Button></Row>}



            {news.imgUrl &&
              <div>{news.extension === "pdf" ?
                <Row className='m-4 px-3'>
                  <Button href={news.imgUrl} className='buttonNews' target="_blank"><FontAwesomeIcon className='iconPDF' icon={faArrowDown} />PDF</Button> </Row> : <Row className='p-3'><img className='imgNews' src={news.imgUrl} /></Row>}</div>
            }


          </Container>
        </Col>
      </Row>

    </Container>
  )
}

export default OneNewsPage