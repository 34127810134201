import React, { useEffect, useState } from 'react'
import { Container, Spinner, Table, Col, Row } from 'react-bootstrap';
import { api, catchErrorAPI } from "../../api/apiConfig";

function LogPage() {

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);


    useEffect(() => {

        fetch();


    }, [])

    const fetch = async () => {
        try {
            const response = await api.get('/activities');

            setLogs(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Table striped bordered hover responsive>
                        <thead>
                            <th>userEmail</th>
                            <th>activityDate</th>
                            <th>activityTime</th>
                            <th>model</th>
                            <th>action</th>
                            <th>httpCode</th>
                            <th>recordId</th>
                            <th>recordTitle</th>
                        </thead>
                        <tbody>
                            {
                                logs.map((log, index) => {
                                    return (
                                        <tr>
                                            <td>{log.userEmail}</td>
                                            <td>{new Date(log.activityTime).toLocaleDateString("fr-FR")}</td>
                                            <td>{new Date(log.activityTime).toLocaleTimeString()}</td>
                                            <td>{log.model}</td>
                                            <td>{log.action}</td>
                                            <td>{log.httpCode}</td>
                                            <td>{log.recordId}</td>
                                            <td>{log.recordTitle}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default LogPage