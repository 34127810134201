import { useEffect, useState } from "react";
import { Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Spinner, Badge } from "react-bootstrap";
import {getFirstCapitalString, getPerformanceString, getTabEpreuvesTrie} from "../../Utils/utilis";
import { api, catchErrorAPI } from "../../api/apiConfig";

function TopSaison({ sexe, saison }) {

    const [tops, setTops] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Top " + saison + " " + sexe + " | UAC";
        setTops(null);

        fetch();
    }, [sexe, saison]);

    const fetch = async () => {
        setLoading(true);
        try{
          const response = await api.get(`/performances/top5/${saison.toLowerCase()}/${sexe.substring(0,1)}`);

          setTops(response.data);
          setLoading(false);
        }catch(err){
          catchErrorAPI(err);
        }
      };

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }

    const displayCat = (type) => {
        if(type === "60 m haies" || type === "poids" || type === "javelot" || type === "disque" ||  type === "100 m haies" || type === "400 m haies" ){
            return true;
        }
        return false;
    }

    return (
        <Container>
            <Row className="text-center pt-3">
                <h1 className="titleh1"><span className="jauneTitre">Top 5 saison {saison} 2024  {sexe === "F" ? "femmes" : "hommes"}</span></h1>
            </Row>

            <Row className='py-3' xs={1} md={2} lg={3}>
                {
                    tops && tops.map(element => {
                        return (
                            <Col className='py-3' key={element.epreuve._id} >
                                <Card >
                                    <Card.Header id="cardTop" className="titreTop">{getFirstCapitalString(element.epreuve.label)}</Card.Header>
                                    <ListGroup className="listeTop" variant="flush">
                                        {element.performances.map((perf, index) => {
                                            return (
                                                <ListGroupItem key={index} className="listeTop-item" >
                                                    {index + 1}. {perf.nom} {` `}
                                                    { displayCat(element.epreuve.label) && <Badge bg="light" text="dark">
                                                        {perf.categorie.substring(0, 3).toUpperCase()}
                                                    </Badge>}
                                                    
                                                    <span className="performanceDansTop"> {getPerformanceString(perf.performance, perf.type)} </span>
                                                </ListGroupItem>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default TopSaison;