import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Utils/userContext';
import { api, catchErrorAPI } from '../api/apiConfig';
import * as Yup from 'yup';
import { Container, Spinner, Col, Row, Card, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import imageCompression from 'browser-image-compression';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function FileComponent() {
    const { userAuth, setUserAuth } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [isLoading, setLoadingUpload] = useState(false);
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [initialValues, setInitialValues] = useState({
        label: '',
        urlLink: ''
    });

    const validationSchema = Yup.object().shape({
        label: Yup.string().required('Un titre est requis'),
    });

    const [photo, setPhoto] = useState(null);
    const [extension, setExtention] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const addFile = async (values) => {
        const downloadUrl = await fileUploadHandler();

        const newFileToCreate = {
            label: values.label,
            urlLink: values.urlLink,
        }

        if (photo) {
            newFileToCreate.url = downloadUrl;
            newFileToCreate.extension = extension;
        }

        const postFile = async () => {
            try {
                await api.post(`/files`, newFileToCreate);
                await fetchFiles();

                setMessage({ error: false, msg: "Document ajouté !" });
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                catchErrorAPI(err);
            }
        }
        postFile();
        setInitialValues({
            label: '',
            urlLink: ''
        })

    }

    const fileUploadHandler = async () => {
        if (photo) {
            const storage = getStorage();
            const idPhoto = Math.floor(10000000 + Math.random() * 90000000);
            const storageRef = ref(storage, `files/${idPhoto}`);
            const uploadTask = uploadBytesResumable(storageRef, photo);

            // Créer une promesse qui sera résolue une fois que le téléchargement est terminé
            const downloadUrlPromise = new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgresspercent(progress);
                    },
                    (error) => {
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setPhoto(null);
                            setImgUrl(downloadURL);
                            resolve(downloadURL);
                        });
                    }
                );
            });

            // Attendre que la promesse soit résolue avant de continuer
            const downloadUrl = await downloadUrlPromise;
            return downloadUrl;
        } else {
            return null;
        }
    };

    const fileSelectedHandler = async (e) => {
        setLoadingUpload(true);
        setPhoto(e.target.files[0]);

        const fileExtension = e.target.files[0].name.split('.').pop();
        setExtention(fileExtension);

        if (fileExtension != "pdf") {

            await handleImageUpload(e.target.files[0])
        } else {
            setLoadingUpload(false);
        }

    }

    async function handleImageUpload(imageFile) {
        const options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: 1920
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            setPhoto(compressedFile);
            //console.log(compressedFile.size/1024/1024);
            setLoadingUpload(false);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {

        fetchFiles();
    }, [])

    const fetchFiles = async () => {

        try {
            const response = await api.get('/files');
            setFiles(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const deleteFile = async (id) => {
        try {
            await api.delete(`/files/${id}`);
            await fetchFiles();
        } catch (err) {
            catchErrorAPI(err);
        }

    }

    if (loading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Col>
                {
                    <Container className='px-0'>

                        <Row xs={1} md={1} lg={1}>
                            {files && files.length > 0 && (
                                files.map((file) => {
                                    return (
                                        <Col className='pt-3 px-0 d-flex flex-wrap' key={file._id}>
                                            <Card className='flex-grow-1'>
                                                <Card.Body>
                                                    <Card.Title className="d-flex justify-content-between align-items-center">

                                                        <span>{file.label} </span>
                                                        <div>
                                                            {file.urlLink && <Button href={file.urlLink} target="_blank">Lien</Button>}

                                                            {file.url &&
                                                                <Button href={file.url} target="_blank">Document</Button>
                                                            }
                                                            {userAuth &&
                                                                <Button variant='danger' onClick={() => {deleteFile(file._id)}}>Supprimer</Button>
                                                            }
                                                        </div>

                                                    </Card.Title>
                                                </Card.Body>

                                            </Card>
                                        </Col>
                                    )
                                })
                            )}
                        </Row>
                    </Container>
                }
                {userAuth &&
                    <Row>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                addFile(values);

                            }}
                        >
                            {({ values, errors, touched }) => (
                                <Form>
                                    <label htmlFor="label">Titre du document / lien</label> <br />
                                    <Field id="label" name="label" className="fieldTitleNews" /><br />

                                    <label htmlFor="urlLink">URL du lien (Soit un lien)</label> <br />
                                    <Field id="urlLink" name="urlLink" className="fieldTitleNews" /><br />

                                    <label>Image ou pdf (Soit un document)</label> <br />
                                    <input type='file' accept=".jpg,.png,.jpeg,.pdf" onChange={fileSelectedHandler} />
                                    <br />
                                    <div>
                                        <p><ErrorMessage name="titre" /></p>
                                        <p><ErrorMessage name="auteur" /></p>
                                        <p><ErrorMessage name="type" /></p>
                                    </div>


                                    <Button variant="primary" type="submit" className="mt-3" disabled={isLoading}
                                    >

                                        {isLoading ? 'Chargement...' : 'Ajouter'}
                                    </Button>
                                </Form>)}
                        </Formik>
                    </Row>}
            </Col>
        </Container>
    )
}

export default FileComponent