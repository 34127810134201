import React from 'react';
import QRCode from 'qrcode.react';
import { Container, Row, Col } from 'react-bootstrap';

function BancontactQRCode({montant, lastName, firstName, eventName}) {
  // Informations spécifiques à Bancontact
  const bancontactData = `
      BCD
      001
      1
      SCT
      
      UAC ASBL
      BE88142063090441
      EUR${montant}

      Soiree des etoiles - ${lastName} ${firstName}
  `;
  return (
    <Container>
      <Row>
        <Col>
        <h3 className="titleh1"><span className="jaune">Veuillez régler par virement bancaire pour confirmer </span></h3> <br/>
          <ul>
            <li>NOM : UAC ASBL</li>
            <li>IBAN : BE88 1420 6309 0441</li>
            <li>Montant : {montant} €</li>
            <li>Communication : {eventName} - {lastName}</li>
          </ul>
        </Col>
{/*         <Col >
          <h3 className="titleh1"><span className="jaune">Ou directement via votre application bancaire</span></h3> <br/>
          <QRCode value={bancontactData} />
        </Col> */}
      </Row>
    </Container>
  );
}

export default BancontactQRCode;