import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function NotFound() {
    let navigate = useNavigate();
    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Cette page n'existe pas ou a été supprimée. </span></h1>
                </Col>
            </Row>
            <Row className="text-center pt-3">
                <Col>
                    <Button className="mt-5 p-3 buttonJaune" onClick={() => { navigate("/") }}><h2>Page d'accueil</h2></Button>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound