import React, {useState, useContext, useEffect} from 'react'
import {Container, Row, Col, Alert, Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {api, catchErrorAPI} from "../api/apiConfig";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import '../pages/Inscription/inscription.css'
import InputMask from 'react-input-mask';
import Select from "react-select";


const ContactForm = () => {
    let navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    let [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [groupOptions, setGroups] = useState(false);
    const [isAdult, setIsAdult] = useState(false);


    useEffect(() => {
        setLoading(false)
    }, []);

    const checkIfAdult = (birthdate) => {
        if (!birthdate) return false;
        const birthDate = new Date(birthdate);

        return (birthDate.getFullYear() > 1900 && birthDate.getFullYear() <= 2011 );


    };


    const addInscription = async (values) => {
        setIsLoading(true)
        try {

            let inscriptionValues = {
                firstname: values.firstname.trim(),
                lastname: values.lastname.trim(),
                birthdate_date: values.birthdate_date,
                title: values.title,
                street: values.street.trim(),
                numStreet: values.numStreet.trim(),
                zip: values.zip,
                city: values.city,
                email: values.email.trim().toLowerCase(),
                emailAdressConfirmation: values.email.trim().toLowerCase(),
                mobile: values.mobile,
                groups:values.groups,

                renouvellement: values.renouvellement,
                rgpdConsent: values.rgpdConsent,

                inscriptionDate: new Date(Date.now()),
                status : "formulaire"
            }

            let data = await api.post(`/inscription`, inscriptionValues);

            console.log(data);

            setIsLoading(false);

            navigate(`/sign/${data.data._id}`, { state: { userData: data.data } });

        } catch (error) {
            setMessage({ error: true, msg: error.message });
            setIsLoading(false);
        }

    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }


    return (
        <Container>
            <Row>
                <Col>
                    <Formik
                        initialValues={{
                            firstname: 'Jean',
                            lastname: 'Dupont',
                            email: 'yvl@test.com',
                            emailAdressConfirmation: 'yvl@test.com',
                            typeQuestion : '',
                            question : '',
                            rgpdConsent: false,
                        }}
                        validationSchema={Yup.object({
                            firstname: Yup.string()
                                .required('Prénom requis')
                                .min(2, 'Le prénom doit comporter au moins 2 caractères'),
                            lastname: Yup.string()
                                .required('Nom requis')
                                .min(2, 'Le nom doit comporter au moins 2 caractères'),
                            email: Yup.string()
                                .required('Email requis')
                                .matches(
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    'Veuillez fournir une adresse email valide'
                                ),
                            emailAdressConfirmation: Yup.string()
                                .required('La confirmation d\'email est requise')
                                .oneOf([Yup.ref('email')], 'Les adresses email ne correspondent pas'),

                            rgpdConsent: Yup.boolean().oneOf([true], 'Vous devez donner votre consentement pour utiliser vos données'),


                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            addInscription(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit} className="p-4 m-1 mt-3 containerForm">

                                <Row className="mb-3 jaune">
                                    <h2>Informations de contact</h2>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="firstname">
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstname"
                                            placeholder="ex : Jean"
                                            value={values.firstname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.firstname && !errors.firstname}
                                            isInvalid={touched.firstname && !!errors.firstname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstname}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="lastname">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastname"
                                            placeholder="ex : Dupont"
                                            value={values.lastname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.lastname && !errors.lastname}
                                            isInvalid={touched.lastname && !!errors.lastname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastname}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="ex : exemple@mail.com"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && !!errors.email}
                                            isValid={touched.email && !errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 jaune">
                                    <h2>Question</h2>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="emailDomain">
                                        <Form.Label>Type de question</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="emailDomain"
                                            value={values.emailDomain}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.emailDomain && !!errors.emailDomain}
                                            isValid={touched.emailDomain && !errors.emailDomain}
                                        >
                                            <option value="">Choisir un type de question...</option>
                                            <option value="@gmail.com">Inscriptions</option>
                                            <option value="@yahoo.com">Compétitions</option>
                                            <option value="@outlook.com">Remboursement mutuel</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.emailDomain}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="8" sm="9" controlId="street">
                                        <Form.Label>Question</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            name="question"
                                            placeholder="Entrez votre question"
                                            value={values.question}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.question && !!errors.question}
                                            isValid={touched.question && !errors.question}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.question}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="rgpdConsent">
                                        <Form.Check
                                            type="checkbox"
                                            label={
                                            <>
                                                J'accepte la <a href="https://www.uachatelineau.be/rgpd" target="_blank">politique de confidentialité</a>
                                            </>
                                            }
                                            checked={values.rgpdConsent}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.rgpdConsent && !!errors.rgpdConsent}
                                            isValid={touched.rgpdConsent && !errors.rgpdConsent}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.rgpdConsent}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md="3"></Col>
                                    <Col md="6">
                                        <Button  className="buttonJaune" size="lg" type="submit" disabled={isLoading}>{isLoading ? 'Chargement...' : 'Signer l\'attestation sur l\'honneur '}<FontAwesomeIcon icon={faArrowRight} /></Button>
                                        {message?.msg && (
                                            <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                                                {message?.msg}
                                            </Alert>)
                                        }
                                    </Col>
                                    <Col md="3"></Col>

                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactForm