
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");



const firebaseConfig = {
  apiKey: "AIzaSyDmIXi_htcnJ_whwgIUwCKJdU_fqOIk2V8",
  authDomain: "uacperformance.firebaseapp.com",
  projectId: "uacperformance",
  storageBucket: "uacperformance.appspot.com",
  messagingSenderId: "416103022239",
  appId: "1:416103022239:web:d7fec94062ed9cee4bacaa",
  measurementId: "G-CQ9PC4S9XP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/*
// reCAPTCHA 
//window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lf8ossjAAAAALbUe7Ju6V_a6iXF02QDEGiYVWL8'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});*/

export const db = getFirestore(app);
export const auth = getAuth(app);
