import { Container, Row, Card, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Social() {
    return (
        <Container className="bloc">
            <Row className="text-center">
                <h1 className="titleHomePage"><span className="jaune"><FontAwesomeIcon  icon={faThumbsUp} /> Nos réseaux sociaux</span></h1>
            </Row>
            <Row xs={1} md={1} lg={1}>

                <Col className='pt-3 d-flex flex-wrap' key="fb1">
                    <Card className='flex-grow-1'>
                        <Card.Body>
                            <Card.Title >Groupe Facebook</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Pour avoir toutes les infos </Card.Subtitle>
                            <Card.Text >
                            <Button href="https://www.facebook.com/groups/148271265246059/" target="_blank" rel="noreferrer" className="buttonJaune">Groupe UAC <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='pt-3 d-flex flex-wrap' key="fb2">
                    <Card className='flex-grow-1'>
                        <Card.Body>
                            <Card.Title >Groupe Facebook EDJ </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Pour l'école des jeunes </Card.Subtitle>
                            <Card.Text >
                                <Button href="https://www.facebook.com/groups/1683666021894243/" target="_blank" rel="noreferrer" className="buttonJaune">Groupe EDJ <FontAwesomeIcon icon={faArrowRight} /></Button>
                                
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='pt-3 d-flex flex-wrap' key="insta">
                    <Card className='flex-grow-1'>
                        <Card.Body>
                            <Card.Title >Instagram</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Pour suivre la vie du club </Card.Subtitle>
                            <Card.Text >
                                <Button className="buttonJaune" href="https://instagram.com/uac.athletisme" target="_blank" rel="noreferrer">Instagram <FontAwesomeIcon icon={faArrowRight} /></Button>
                                
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Social;