import { Container, Form,Button, Alert, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { api } from "../../api/apiConfig";


function ResetPassword(){
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        document.title = "Réinitiliser mot de passe | UAC";  
      }, []);

    const login = async(e) => {
        e.preventDefault();

        logout();

        try {
            await api.post('/ResetPassword', { email: email });

            setMessage({error: false, msg: "Un mail a été envoyé à l'adresse mail pour réinitialiser le mot de passe."});
        
          } catch (error) {
            
            setMessage({error: true, msg: error.response.data});
          }
        
        setEmail("");
    }

    const logout = () => {
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
    }

    return(
    <Container>
        <Row className='py-3 text-center'>
            <h1 className="titleh1">Réinitialiser le mot de passe</h1>
        </Row>
        <Row className="">
            <Container>
                <Row>
                {message?.msg && (
                <Alert variant={ message?.error ? "danger" : "success"} dismissible onClose={ () => setMessage("")}>
                    {message?.msg}
                </Alert>)
                }
                </Row>
                <p>
                Veuillez indiquer votre e-mail pour modifier votre mot de passe. Un mail vous sera envoyé. 
                </p>
                
                <Row  className="mx-5">
                <Form onSubmit={login}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} 
                    onChange={(event) => {setEmail(event.target.value)}} />
                <Col className="mt-2">
                <Button variant="primary" type="submit">
                Réinitialiser mon mot de passe
                </Button>
                
                </Col>
                </Form>
                </Row>
                
            </Container>
        </Row>
    </Container>
)}

export default ResetPassword;