import axios from "axios";

export const api = axios.create({
    baseURL : process.env.REACT_APP_API_URL, 
    //baseURL : 'https://api.uacperformance.be',
    //baseURL : 'http://localhost:3000',
    headers: {
      "Content-type": "application/json",
    }
});


api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});


api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Rediriger vers la page de login
      console.log("redirect")

      localStorage.removeItem('authUser');
      localStorage.removeItem('token');

      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);



export function catchErrorAPI(err){
    if(err.message && err.response){
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }else{
        console.log("Error API");
      }
}