import { Container, Row, Table, Button, Form, Alert, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { api, catchErrorAPI } from "../../api/apiConfig";


function GestionCompetionPage() {
    const [competions, setCompetitons] = useState([]);
    const [changeVisibily, setChangeVisibily] = useState(0);

    const [newDate, setNewDate] = useState();
    const [newNom, setNewNom] = useState("");
    const [newSaison, setNewSaison] = useState("");
    const [message, setMessage] = useState("");
    const [disableSearchResult, setDisableSearchResult] = useState(false);

    useEffect(() => {
        fetchCompet();
    }, [changeVisibily]);

    const fetchCompet = async () => {
        try {
            const response = await api.get('/competitions/latest');
            setCompetitons(displayArray(response.data));
        } catch (err) {
            catchErrorAPI(err);
        }
    };


    const displayArray = (tab) => {

        const ids = tab.map(o => o._id);
        const filtered = tab.filter(({ _id }, index) => !ids.includes(_id, index + 1));

        filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
        return filtered;
    }

    const createCompetition = async (e) => {
        e.preventDefault();

        const newCompetition = {
            date: new Date(newDate),
            nom: newNom,
            saison: newSaison,
            estAffiche: true
        }

        const post = async () => {
            try {
                await api.post(`/competitions`, newCompetition);
                await fetchCompet();
                setMessage({ error: false, msg: "Compétition ajoutée !" });
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                catchErrorAPI(err);
            }
        }
        post();

        setNewDate("");
        setNewNom("");
        setNewSaison("");
    };
    const handleChangeSwitch = async (value, id) => {
        const putNews = async () => {
            try {
                await api.put(`/competitions/${id}`, {
                    estAffiche: !(value)
                });
                await fetchCompet();
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
            }
        }
        putNews();

    };

    const searchResult = async () => {

        setDisableSearchResult(true);
        const searchResultAPI = async () => {
            try {
                await api.post(`/searchResult`);
                setDisableSearchResult(false);
            } catch (err) {
                setMessage({ error: true, msg: "Erreur search : " + err.msg  });
                setDisableSearchResult(false);
            }
        }
        searchResultAPI();

    };



    return (
        <Container>
            {message?.msg && (
                <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                    {message?.msg}
                </Alert>)
            }
            <Row>
                <Col>
                    <h2>Ajouter  une compétition</h2>
                </Col>
                <Col>
                    <Button onClick={searchResult} disabled={disableSearchResult}>Rechercher de nouveaux résulats</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Nom</th>
                                <th>Saison</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                competions.map((competition => {
                                    return (
                                        <tr key={competition.id}>
                                            <td>{new Date(competition.date).toLocaleDateString("fr-FR")}</td>
                                            <td>{competition.nom}</td>
                                            <td>{competition.saison}</td>
                                            <td><FontAwesomeIcon icon={competition.estAffiche ? faSquare : faSquareCheck} onClick={(event) => { handleChangeSwitch(competition.estAffiche, competition._id) }} />  &nbsp;
                                                <span style={{ color: competition.estAffiche ? "#FF8C00" : "green" }}>{competition.estAffiche ? "Encodage en cours" : "Encodage terminé"}</span></td>
                                        </tr>
                                    )
                                }))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col className="mx-5">
                    <Form onSubmit={createCompetition} >
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" placeholder="Enter date" value={newDate}
                            onChange={(event) => { setNewDate(event.target.value) }} required />

                        <Form.Label>Nom</Form.Label>
                        <Form.Control type="text" placeholder="Enter nom" value={newNom}
                            onChange={(event) => { setNewNom(event.target.value) }} required />

                        <Form.Label>Saison</Form.Label>
                        <Form.Select aria-label="Default select example" value={newSaison}
                            onChange={(event) => { setNewSaison(event.target.value) }} required>
                            <option value="">Saison</option>
                            <option value="Indoor">Indoor</option>
                            <option value="Outdoor">Outdoor</option>
                            <option value="Cross">Cross</option>
                        </Form.Select>

                        <Button className="mt-2" variant="primary" type="submit">
                            Ajouter compétition
                        </Button>
                    </Form>
                </Col>

            </Row>

        </Container>
    )
}

export default GestionCompetionPage;