import React from 'react';
import { useState } from "react";
import './searchBar.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import {cacheExpiration, getInitial} from '../../Utils/utilis';
import {api, catchErrorAPI} from "../../api/apiConfig";



function SearchBar({placeholder, onCloseModal}) {
    let navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    let [athletes, setAthletes] = useState([]);

    const handleFilter = (event) => {
        console.log("handleFilter");
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = athletes.filter((value) => {
            return value.prenom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchWord.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        });

        if(searchWord === ""){
            setFilteredData([]);
        }else{
            setFilteredData(newFilter);
        }
    }
    
    const handleClick = (id) => {
        console.log("handleClick");
        setFilteredData([]);
        setWordEntered("");
        navigate(`/athlete/${id}`);
        if(onCloseModal){
            onCloseModal();
        }
    }

    const loadAthlete = async() => {
        console.log("loadAthlete");

        if(athletes.length < 1){
            const cachedData = localStorage.getItem("athletesList");

            const cachedDataAthletesTimestamp = localStorage.getItem("timestampAthletesList");
            const currentTime = new Date().getTime();

            if (cachedData && cachedDataAthletesTimestamp && currentTime - cachedDataAthletesTimestamp < cacheExpiration) {
                setAthletes(JSON.parse(cachedData));
            } else {
                await fetchAthletes(currentTime);
            }

        }
    }

    const fetchAthletes = async (currentTime) => {
        try {
            const response = await api.get('/athletes');
            setAthletes(response.data);
            localStorage.setItem("athletesList", JSON.stringify(response.data));
            localStorage.setItem("timestampAthletesList", currentTime);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    }

  return (
    <div className='search'>
        <div className='searchInputs'>
            <input type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter} onClick={loadAthlete} />
            <div className='searchIcon'>
                {wordEntered.length === 0 ? <FontAwesomeIcon className='icon' id="faMagnifyingGlass" icon={faMagnifyingGlass}/> : 
                <FontAwesomeIcon className='icon' id="clearBtn" icon={faXmark} onClick={clearInput} />}
                
            </div>
        </div>
        { filteredData.length !== 0 && (
        <div className='dataResult'>
            {filteredData.slice(0,4).map((value, key) => {
                return <a className='dataItem' onClick={() => {handleClick(value._id)}}>{getInitial(value.prenom, value.nom)}</a>
            })}
        </div>
        )}
    </div>
  )
}

export default SearchBar