import React, { useEffect } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import FileComponent from '../../components/FileComponent'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CarouselComponent from '../../components/Inscription/CarouselComponent';

function JoinPage() {
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Inscription | UAC";

  }, []);


  return (
    <Container>
      <Row className="text-center pt-3">
        <Col>
          <h1 className="titleh1"><span className="jauneTitre">Inscription à l'UAC</span></h1>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='mx-2 bloc'>
          <Container >
            <Row>
              <Col>
                <h3 className="titleh1"><span className="jaune">Le club</span></h3>
              </Col>
            </Row>
            <Row xs={1} md={2} lg={2}>
              <Col className='mt-2'>
                <ul className='listInfra'>
                  <li><b>3 essais gratuits avant de vous affilier</b>
                    <ul>
                      <li>Venez 5min avant l'entrainement pour en parler au coach</li>
                    </ul>
                  
                  </li> 
                  <li>Groupes pour les enfants, les ados et les adultes</li>
                  <li>À proximité du bois de Soleilmont</li>
                  <li>Vestiaires & Douches</li>
                  <li>Club house</li>
                </ul>
                <Button className="buttonJaune" onClick={() => {navigate(`/groupes`)}}>Les groupes & horaires <FontAwesomeIcon icon={faArrowRight} /></Button> <br/>  <br/>
                <Button className="buttonJaune" onClick={() => {navigate(`/comment-s-inscrire`)}}>Procédure d'inscription en ligne <FontAwesomeIcon icon={faArrowRight} /></Button>

              </Col>
              <Col className='mt-2'>
                <CarouselComponent></CarouselComponent>
              </Col>
            </Row>
          </Container>

        </Col>
      </Row>
      <Row>
        <Col className='mx-2 bloc'>
          <h3 className="titleh1"><span className="jaune">Cotisation</span></h3><br />
          <p>
            La cotisation comprend :
            <ul>
              <li>La licence fédérale et le dossard</li>
              <li>L'assurance</li>
              <li>L'utilisation des installations du club</li>
              <li>La mise à disposition d'un entraîneur</li>
              <li>Le droit d'inscription aux divers championnats L.B.F.A. et C.P.H.</li>
            </ul>
          </p>
          <Table striped bordered hover className='center-table'>
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>Année de naissance</th>
                <th>Cotisation</th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td>Kangourou</td>
                <td>2019-2018</td>
                <td>
                  <b>110 €</b><br />
                  (20 € remboursé si 4 compétitions)
                </td>
              </tr>
              <tr>
                <td>Benjamin</td>
                <td>2017-2016</td>
                <td rowSpan={3}>
                  <b>145 €</b><br />
                  (30 € remboursé si 8 compétitions)
                </td>
              </tr>
              <tr>
                <td>Pupille</td>
                <td>2015-2014</td>
              </tr>
              <tr>
                <td>Minime</td>
                <td>2013-2012</td>
              </tr>
              <tr>
                <td>Cadet à master</td>
                <td>à partir de 2011</td>
                <td>
                  <b>125 €</b><br />
                  
                </td>
              </tr>
              <tr>
                <td>Section marche</td>
                <td></td>
                <td>
                  <b>45 €</b><br />
                </td>
              </tr>
              <tr>
                <td>À partir du 4ème membre de la famille</td>
                <td></td>
                <td>
                  <b>60 €</b><br />
                </td>
              </tr>
            </tbody>
          </Table>
          <h3 className="titleh1"><span className="jaune">Mutuelle</span></h3><br />
          <p>
            Intervention de certaines mutuelles dans les cotisations. Le demande d’intervention doit être
            remise dans le bac « secrétaire » et reprise dans le bac « athlètes » situés au club house
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='mx-2 bloc'>
          <h3 className="titleh1"><span className="jaune">Documents et liens utiles</span></h3>
          <FileComponent></FileComponent>
        </Col>

      </Row>
    </Container>
  )
}

export default JoinPage