import { Container, Row, Nav, Col} from "react-bootstrap";
import { useState, useEffect } from "react";

import {
    useNavigate, useParams
  } from "react-router-dom";


import TabTopClub from "./TabTopClub";
import { getLabelCat } from "../../Utils/utilis";


function TopClub(){
    let navigate = useNavigate();
    const { sexe, saison, categorie } = useParams();

    const [activeKey, setActiveKey] = useState(`/TopClub/${sexe}/${saison}/TC`);

    useEffect(() => {
        if (categorie && categorie !== undefined) {
            setActiveKey(`/TopClub/${sexe}/${saison}/${categorie}`);
        }
    }, [sexe, saison, categorie]);

      

    return(
    <Container>
        <Row className="text-center py-3">
             <h1 className="titleh1"><span className="jauneTitre">Top 10 de l'UAC - {sexe} {saison}</span></h1> 
        </Row>
        <Row>
            <Col>
            <Container>
                <Row className="bloc">
                    <Nav id="NavGroup" variant="pills"  activeKey={activeKey} onSelect={(key) => {
                    setActiveKey(key);
                    navigate(key);
                    }} className="flex-column flex-sm-row">
                        {window.listeCategoriesTop.map(cat => { return (
                            <Nav.Item  key={`/TopClub/${sexe}/${saison}/${cat}`}>
                                <Nav.Link 
                                    eventKey={`/TopClub/${sexe}/${saison}/${cat}`} >
                                         {getLabelCat(cat,sexe)}
                                </Nav.Link>
                            </Nav.Item>
                        )})}
                    </Nav>
                </Row>
                

                <Row className='py-3' >
                    <TabTopClub sexe={sexe} saison={saison} categorie={categorie}/>
    
                </Row>
            </Container>
            </Col>
        </Row>
    </Container>
)}

export default TopClub;