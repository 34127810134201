
import {Container, Row, Button, Alert, Spinner} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { api, catchErrorAPI } from "../../api/apiConfig";
import {useNavigate} from "react-router-dom";
import {ErrorMessage, Field, Formik, Form} from "formik";
import * as Yup from "yup";


function GestionAthletePage({ type, athleteToEdit, changeVisibility, fetchAthlete  }) {

    let navigate = useNavigate();


    const [initialValues, setInitialValues] = useState({
        prenom: '',
        nom: '',
        dossard: '',
        categorie: '',
        idsGroups: '',
        sexe: '',
        commentaire: '',
        anneeInscription: ''
    });

    const validationSchema = Yup.object().shape({
        prenom: Yup.string().required('Un prenom est requis')
    });

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);

    let[groupList, setGroupList] = useState();


    useEffect(() => {

        if (type === "edit") {


            let idsGroupExtract = athleteToEdit.idsGroups.map(g => g._id)

            setInitialValues({
                prenom: athleteToEdit.prenom,
                nom: athleteToEdit.nom,
                dossard: athleteToEdit.dossard,
                categorie: athleteToEdit.categorie,
                idsGroups: idsGroupExtract,
                sexe: '',
                commentaire: (athleteToEdit.commentaire) ? athleteToEdit.commentaire : "",
                anneeInscription: (athleteToEdit.anneeInscription) ? athleteToEdit.anneeInscription : "",
            })

        }
        fetchGroupList();
    }, [athleteToEdit, type]);

    const fetchGroupList = async () => {

        try{
            const response = await api.get('/groupsList');
            setGroupList(response.data);
            setLoading(false);
        }catch(err){
            catchErrorAPI(err);
        }
    }



    const createAthlete = async (values) => {

        let sexe = 'H';

        if (values.categorie.substring(values.categorie.length - 1) === 'F') {
            sexe = 'F'
        }

        if (values.idsGroups.length !== 0) {

            if (type === "add") {
                const newAthlete = {
                    prenom: values.prenom,
                    nom: values.nom,
                    dossard: values.dossard,
                    categorie: values.categorie,
                    idsGroups: values.idsGroups,
                    anneeInscription: values.anneeInscription,
                    sexe: sexe
                }

                const post = async () => {
                    try {
                        await api.post(`/athletes`, newAthlete);

                        setMessage({ error: false, msg: "Athlète ajouté !" });
                    } catch (err) {
                        setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                        catchErrorAPI(err);
                    }
                }
                post();

            } else {
                const newAthlete = {
                    prenom: values.prenom,
                    nom: values.nom,
                    dossard: values.dossard,
                    categorie: values.categorie,
                    idsGroups: values.idsGroups,
                    anneeInscription: values.anneeInscription,
                    sexe: sexe,
                    commentaire: values.commentaire
                }


                const put = async () => {
                    try {
                        await api.put(`/athletes/${athleteToEdit._id}`, newAthlete);
                        setMessage({ error: false, msg: "Athlète modifié !" });

                    } catch (err) {
                        setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                        catchErrorAPI(err);
                    }
                }
                put();
                fetchAthlete();
                changeVisibility(false);
            }



            setInitialValues({
                prenom: '',
                nom: '',
                dossard: '',
                categorie: '',
                idsGroups: '',
                sexe: '',
                commentaire: '',
                anneeInscription: ''
            })
            //window.location.reload();
        } else {
            setMessage({ error: true, msg: "Groupe vide" });
        }

    }

    const archiveAthlete = () => {
        const put = async () => {
            try {
                await api.put(`/athletes/${athleteToEdit._id}`, {archived : true});
                setMessage({ error: false, msg: "Athlète modifié !" });
                window.location.reload();
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                catchErrorAPI(err);
            }
        }
        put();
    }

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }

    return (
        <Container className="mt-2">
            {message?.msg && (
                <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                    {message?.msg}
                </Alert>)
            }
            <Row>
                {type === "add" ? <Button variant='secondary' className="mt-3"  onClick={() => {navigate("/admin/athlete/archived")}} >Athlètes archivés</Button> : null}
                {type === "add" ? <h1>Ajouter un athlète</h1> : null}

            </Row>
            <Row>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        createAthlete(values);
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form>
                            <label htmlFor="prenom">Prénom</label> <br />
                            <Field id="prenom" name="prenom" className="fieldTitleNews" /><br />

                            <label htmlFor="nom">Nom</label> <br />
                            <Field id="nom" name="nom" className="fieldTitleNews" /><br />

                            <label htmlFor="dossard">Dossard</label> <br />
                            <Field id="dossard" name="dossard" className="fieldTitleNews" /><br />

                            <label htmlFor="anneeInscription">Année d'inscription</label> <br />
                            <Field id="anneeInscription" name="anneeInscription" className="fieldTitleNews" /><br />

                            <label htmlFor="commentaire">Commentaire</label><br />
                            <Field id="commentaire" name="commentaire" as="textarea" component="textarea" className="fieldContentNews" /><br />

                            <label htmlFor="categorie">Catégorie</label><br />
                            <Field name="categorie" as="select">
                                <option value="" disabled> -- Catégorie -- </option>
                                {
                                    window.listeCategories.map((cat) => (
                                        <option value={cat} key={cat}>{cat}</option>
                                    ))
                                }
                            </Field> <br />


                            <div id="checkbox-group" className='titleForm'>Groupes</div>
                            <div role="group" aria-labelledby="checkbox-group">
                                {
                                    groupList.map(g => {
                                        return (
                                            <label className='labelCoachForm'>
                                                <Field type="checkbox" name="idsGroups" value={g._id} key={g._id}/>
                                                {g.name}
                                            </label>
                                        )
                                    })
                                }
                            </div>


                            <div>
                                <p><ErrorMessage name="titre" /></p>
                                <p><ErrorMessage name="auteur" /></p>
                                <p><ErrorMessage name="type" /></p>
                            </div>

                            {type === "edit" ? <div>
                                <Button className="m-2" variant="primary" type="submit"> Confirmer</Button>
                                <Button className="m-2" variant="danger" onClick={archiveAthlete}>Archiver</Button>

                            </div> : <Button className="mt-2" variant="primary" type="submit">Ajouter</Button>}

                        </Form>)}
                </Formik>


            </Row>

        </Container>
    )
}

export default GestionAthletePage;