import {Container, Row, Col, Card, Spinner, Button} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { getInitial, getPerformanceString } from "../../Utils/utilis";
import { api, catchErrorAPI } from "../../api/apiConfig";
import FormTransferDiscipline from "../../components/Performance/FormTransferDiscipline";
import {UserContext} from "../../Utils/userContext";


function FicheCompetiton() {
  const { idCompetiton } = useParams();
  let navigate = useNavigate();
  const { userAuth } = useContext(UserContext);

  const [competition, setCompetiton] = useState("");

  const [loading, setLoading] = useState(true);
  const [disciplines, setDisciplines] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState(null);

  useEffect(() => {
    
    fetchCompet();
    fetchPerformances();
    setLoading(false);
  }, []);

  const fetchCompet = async () => {
    try{
      const response = await api.get(`/competitions/${idCompetiton}`);
     
      setCompetiton(response.data);
      document.title = response.data.nom + " | UAC";
      
    }catch(error){
      catchErrorAPI(error);
    }
  }

  const fetchPerformances = async () => {
    try{
      const response = await api.get(`/performances/competition/${idCompetiton}`);
      setDisciplines(response.data);
    }catch(error){
      catchErrorAPI(error);
    }
  }




  if (loading) {
    return (
      <Container className="bloc">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </Container>
    )
  }

  return (
      <Container>
        <Row>
          <Col className="text-center py-3">
            <h1 className="titleh1"><span
                className="jaune">{new Date(competition.date).toLocaleDateString("fr-FR")} - {competition.nom}</span>
            </h1>
          </Col>
        </Row>
        <Row xs={1} md={1} lg={1}>
          <Col>
            {disciplines.map((dis) => (
                <Container key={dis} className="bloc">
                  <Row>
                    <Col><h2>{dis.label}</h2></Col>
                    <Col>{userAuth && <Button onClick={(key) => {
                      setSelectedDiscipline(dis._id)
                    }}>Transférer discipline</Button>}</Col>
                  </Row>
                  <Row xs={2} md={3} lg={4}>
                    {
                      dis.performances.map(perf => {
                        return (
                            <Col className='pt-3 d-flex flex-wrap'>
                              <Card className="flex-grow-1">
                                <Card.Body>
                                  <Card.Title onClick={() => {
                                    navigate(`/athlete/${perf.idAthlete}`)
                                  }}>{getInitial(perf.prenomAthlete, perf.nomAthlete)} <FontAwesomeIcon
                                      icon={faAnglesRight} className="iconTransparence"/>
                                  </Card.Title>
                                  <Card.Text>
                                    {getPerformanceString(perf.performance, perf.type)}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                        )
                      })
                    }
                    {selectedDiscipline && selectedDiscipline === dis._id &&
                        <FormTransferDiscipline oldDiscipineId={dis._id} fetchPerformances={fetchPerformances}
                                                oldDisciplineSaison={dis}></FormTransferDiscipline>}
                  </Row>
                </Container>
            ))}
          </Col>
        </Row>
      </Container>
  );
}

export default FicheCompetiton;