import {Container, Row, Col, ListGroup, Button, Badge, Spinner} from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import PerfComponent from "../../components/Performance/PerfComponent";
import { UserContext } from "../../Utils/userContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faHashtag, faRunning, faUsers, faChartBar, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { getButtonColor } from "../../Utils/utilis";
import { api, catchErrorAPI } from "../../api/apiConfig";
import GestionAthletePage from "../Admin/GestionAthletePage";
import SeasonSelector from "../../components/Performance/SeasonSelector";


function FichePage() {
    const { idAthlete } = useParams();
    const { userAuth } = useContext(UserContext);
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [athlete, setAthlete] = useState({ groupe: [] });
    const [selectedSeason, setSelectedSeason] = useState(null);


    const [nbPerfIndoor, setNbPerfIndoor] = useState(0);
    const [nbPerfOutdoor, setNbPerfOutDoor] = useState(0);
    const [nbPerfCross, setNbPerfCross] = useState(0);

    const [editIsShown, setEditIsShown] = useState(false);



    useEffect(() => {
        fetchAthlete();
    }, [idAthlete]);

    const fetchAthlete = async () => {
        try {
            const response = await api.get(`/athletes/${idAthlete}`);
            setAthlete(response.data);
            document.title = response.data.prenom + " " + response.data.nom[0] + ". | UAC";

            const response2 = await api.get(`/performances/count/athlete/${idAthlete}`);
            setNbPerfIndoor(response2.data.nbIndoor);
            setNbPerfOutDoor(response2.data.nbOutdoor);
            setNbPerfCross(response2.data.nbCross);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }



    const handleEditClick = event => {
        setEditIsShown(current => !current);
    }

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row className='py-3 d-flex align-items-center justify-content-between' xs={3} md={3} lg={3}>
                <Col xs={1} md={2} lg={2} className="text-left">
                    <span className="returnButton" onClick={() => { navigate(-1) }}>
                        <FontAwesomeIcon id="faChevronLeft" icon={faArrowLeft} />
                        <span className="d-none d-md-inline"> Retour</span>
                    </span>
                </Col>
                <Col xs={10} md={10} lg={10} className="text-center"><h1 className="titleh1"><span className="jauneTitre">{athlete.prenom} {athlete.nom}</span></h1></Col>
                <Col xs={1} md={2} lg={2}></Col>
            </Row>
            <Row>
                <Col sm={3} className='py-3'>
                    <ListGroup id="listGroupFiche">
                        <ListGroup.Item><FontAwesomeIcon icon={faHashtag} className="icon" />Dossard : {athlete.dossard}</ListGroup.Item>

                        {
                            athlete.anneeInscription &&
                            <ListGroup.Item><FontAwesomeIcon icon={faShieldHalved} className="icon" />Au club depuis : {athlete.anneeInscription}</ListGroup.Item>
                        }

                        <ListGroup.Item><FontAwesomeIcon icon={faRunning} className="icon" />Catégorie : {athlete.categorie}</ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faUsers} className="icon" />Groupe :
                            {athlete.idsGroups && athlete.idsGroups.map((g, index) => {
                                return (
                                    <span key={g._id}> {g.name}{index < athlete.idsGroups.length - 1 && ', '} </span>
                                )
                            })}

                        </ListGroup.Item>

                        {nbPerfIndoor !== 0 && <ListGroup.Item><FontAwesomeIcon icon={faChartBar} className="icon" />{nbPerfIndoor} compétition{nbPerfIndoor > 1 ? 's' : ''} <Badge bg={getButtonColor("Indoor")}>{"Indoor"}</Badge></ListGroup.Item>}

                        {nbPerfOutdoor !== 0 && <ListGroup.Item><FontAwesomeIcon icon={faChartBar} className="icon" />{nbPerfOutdoor} compétition{nbPerfOutdoor > 1 ? 's' : ''} <Badge bg={getButtonColor("Outdoor")}>{"Outdoor"}</Badge></ListGroup.Item>}

                        {nbPerfCross !== 0 && <ListGroup.Item><FontAwesomeIcon icon={faChartBar} className="icon" />{nbPerfCross} compétition{nbPerfCross > 1 ? 's' : ''} <Badge bg={getButtonColor("Cross")}>{"Cross"}</Badge></ListGroup.Item>}

                        {userAuth && athlete.commentaire &&
                            <ListGroup.Item variant="dark">Commentaire pour staff : <br />{athlete.commentaire}
                            </ListGroup.Item>}


                    </ListGroup>

                    {userAuth && !editIsShown && <Button className="mt-2" variant="secondary" onClick={handleEditClick}  >Modifier</Button>}

                    {editIsShown && <GestionAthletePage type="edit" athleteToEdit={athlete} changeVisibility={setEditIsShown} fetchAthlete={fetchAthlete}/>}


                </Col>
                <Col sm={9} className='py-3'>
                    
                    <SeasonSelector setSelectedSeason={setSelectedSeason}></SeasonSelector>

                    <PerfComponent idAthlete={idAthlete} athlete={athlete} selectedSeason={selectedSeason} />
                </Col>
            </Row>
        </Container>

    )
}

export default FichePage;