import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Spinner, Form, Badge, Button } from 'react-bootstrap'
import { api, catchErrorAPI } from '../../../api/apiConfig';
import { useNavigate, useParams } from 'react-router-dom';

const CompListRegistration = () => {
    let navigate = useNavigate();
    const { idEvent } = useParams();
    const [registrations, setRegistrations] = useState(null);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [globalParticipants, setGlobalParticipants] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get(`/registrationsCompetition/${idEvent}`);
            setRegistrations(response.data);

            const responseEvent = await api.get(`/event/${idEvent}`);
            setEvent(responseEvent.data);

            setGlobalParticipants(calculateNbParticipants(response.data));

            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    let calculateTotalPrice = (nbDiscipline) => {
        return nbDiscipline * event.generalPrice;
    }



    let calculateNbParticipants = (data) => {
        let nbAthlete = 0;

        data.forEach(r => {
            if (r.status !== "canceled") {
                nbAthlete++;
            }
        });

        return { nbAthlete: nbAthlete }
    }

    const changeStatus = async (registrationId, futurStatus) => {
        const putData = async () => {
            try {
                await api.put(`/registration/${registrationId}`, {
                    status: futurStatus
                });
                await fetchData();
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
            }
        }
        putData();

    };

    const getBadgeVariant = (status) => {
        switch (status) {
            case 'registered':
                return 'warning';
            case 'paid':
                return 'success';
            case 'present':
                return 'primary';
            case 'canceled':
                return 'danger';
            default:
                return '';
        }
    };

    const getBadgeLabel = (status) => {
        switch (status) {
            case 'registered':
                return 'Inscrit';
            case 'paid':
                return 'Payé';
            case 'present':
                return 'Présent';
            case 'canceled':
                return 'Annulé';
            default:
                return '';
        }
    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }


    return (
        <Container>
            <Row className='py-3'>
                <h1 className="text-center">{event.label}</h1>
            </Row>
            <Row>
                <Col>
                    Participants : {globalParticipants.nbAthlete}
                </Col>
                <Col>
                    <Button onClick={() => { navigate(`/admin/competTabPage/${event._id}`) }}>Tableau des inscriptions</Button>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    {registrations.map((registration) => (
                        <Card key={registration._id} className='mt-1'>
                            <Card.Body>
                                <Card.Title>{registration.lastName} {registration.firstName} {registration.club} - {registration.dossard} ({registration.category})
                                    {' '}
                                    <Badge bg={getBadgeVariant(registration.status)}>{getBadgeLabel(registration.status)}</Badge>
                                </Card.Title>
                                <Card.Text>
                                    
                                    {registration.disciplineRegistration.map((displine) => (
                                        <p className='mb-0' key={displine.displine}>
                                            {displine.discipline} : {displine.performance}
                                        </p>
                                    ))}
                                    <p className='mt-2'>
                                        Prix total : {calculateTotalPrice(registration.disciplineRegistration.length)} €
                                    </p>

                                </Card.Text>

                                <Form.Select onChange={(e) => changeStatus(registration._id, e.target.value)} value={registration.status}>
                                    <option value="registered" >Inscrit</option>
                                    <option value="paid" >Payé</option>
                                    <option value="present" >Présent</option>
                                    <option value="canceled" className="status-annule">Annulé</option>
                                </Form.Select>

                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>




        </Container >
    )
}

export default CompListRegistration