import { Container, Form, Row, Button, Col, Alert} from "react-bootstrap";

import { useState, useEffect } from "react";
import { collection, query, onSnapshot, where, orderBy, addDoc} from "firebase/firestore";
import { db } from "../../firebase-config";
import { setPerfIntoTop } from "../../Utils/topGestion";
import { api, catchErrorAPI } from "../../api/apiConfig";

function AddPerfComponent({athlete,fetchPerfByAthlete }){
    const competitionsCollectionRef = collection(db, "competitions");
    const [competions, setCompetitons] = useState([]);

    const [newCompetition, setNewCompetition] = useState();
    const [newEpreuve, setNewEpreuve] = useState();
    const [listeEpreuves, setListeEpreuves] = useState([]);
    const [competitionSelected, setCompetitionSelected] = useState({});
    const [epreuveSelected, setEpreuveSelected] = useState({});
    const [message, setMessage] = useState("");

    const [metre, setMetre] = useState("0");
    const [cm, setCm] = useState("0");
    const [minute, setMinute] = useState("0");
    const [seconde, setSeconde] = useState("0");
    const [centieme, setCentieme] = useState("0");

    useEffect(() => {
        document.getElementById("minute").style.display = "none";
        document.getElementById("seconde").style.display = "none";
        document.getElementById("distance").style.display = "none";

        fetchCompet();
      }, [])

      const fetchCompet = async () => {
        try {
            const response = await api.get('/competitions/displayed');
            setCompetitons(response.data);
        } catch (err) {
            catchErrorAPI(err);
        }   
    };

      const handleSelectedCompetition = (event) => {
        let competititonSelectId = event.target.value;

        document.getElementById("minute").style.display = "none";
        document.getElementById("seconde").style.display = "none";
        document.getElementById("distance").style.display = "none";

        document.getElementById("epreuveSelect").selectedIndex = 0;

        let competitionSelectedTemp = competions.find(c => c._id === competititonSelectId);
        if(competitionSelectedTemp.saison === "Indoor"){
            setListeEpreuves(window.listeEpreuvesIndoor);
        }else{
            if(competitionSelectedTemp.saison === "Outdoor"){
                setListeEpreuves(window.listeEpreuvesOutdoor);
            }else{
                setListeEpreuves(window.listeEpreuvesCross);
            }
            
        }
        setCompetitionSelected(competitionSelectedTemp);
      };

      const handleSelectedEpreuve = (event) => {
        let epreuveSelectedTemp = listeEpreuves.find(e => e.label === event.target.value)
        
        
        let divMinute = document.getElementById("minute");
        let divSeconde = document.getElementById("seconde");
        let divDistance = document.getElementById("distance");


        if(epreuveSelectedTemp.type === "distance"){
            divMinute.style.display = "none";
            divSeconde.style.display = "none";
            divDistance.style.display = "block";
            divDistance.value = "default";
        }else{
            if(epreuveSelectedTemp.type === "minute"){
                divMinute.style.display = "block";

                divSeconde.style.display = "none";
                divDistance.style.display = "none";
                divMinute.value = "default";
            }else{
                divSeconde.style.display = "block";

                divMinute.style.display = "none";
                divDistance.style.display = "none";
                divSeconde.value = "default";
            }

        }
        setEpreuveSelected(epreuveSelectedTemp);
      };

      const createPerf = async (e) => {
        e.preventDefault();

        if(Object.keys(competitionSelected).length === 0 || Object.keys(epreuveSelected).length === 0){
            setMessage({ error: true, msg: "Champ(s) vide(s)" });
            return;
        }


        let perf;

        if(epreuveSelected.type === "distance"){
            perf = metre + '.' + cm;
            perf = parseFloat(perf);
        } else {
            perf = 0

            if(minute !== ""){
                perf = parseInt(minute)* 60000;
            }

            if(seconde !== ""){
                perf += parseInt(seconde) * 1000;
            }
            
            if(centieme !== ""){
                perf += parseInt(centieme) * 10;
            }
        }

        const newPerf= {
             
            idAthlete : athlete._id,
            idCompetition : competitionSelected._id, 
            epreuve : epreuveSelected.label, 
            type : epreuveSelected.type, 
            performance : perf, 
            estHomologue : true,
        }

        const post = async () => {
            try{
              await api.post(`/performances`,newPerf);
              fetchPerfByAthlete();
              //setPerfIntoTop(newPerf);
              setMessage({ error: false, msg: "Performance ajouté !" });
            }catch(err){
              setMessage({ error: true, msg: "Erreur à l'enregistrement" });
              catchErrorAPI(err);
            }
          }
          post();

        setMetre("0");
        setCm("0");
        setMinute("0");
        setSeconde("0");
        setCentieme("0");

        document.getElementById("minute").style.display = "none";
        document.getElementById("seconde").style.display = "none";
        document.getElementById("distance").style.display = "none";

        document.getElementById("competSelect").selectedIndex = 0;
        document.getElementById("epreuveSelect").selectedIndex = 0;
    }


    return(
        <Container>
            {message?.msg && (
            <Alert variant={ message?.error ? "danger" : "success"} dismissible onClose={ () => setMessage("")}>
                {message?.msg}
            </Alert>)
        }
            <h3>Ajouter une performance</h3>
            <Row>
                <Form onSubmit={createPerf}>
                    <Form.Label>Competition</Form.Label>
                    <Form.Select aria-label="Default select example" id="competSelect"  value={newCompetition}
                        onChange={(event) => {handleSelectedCompetition(event)}}>
                    <option value="defaultOption" hidden disabled selected> -- Compétition -- </option>
                    {
                        competions.map((competition , index) => (
                            <option key={index} value={competition._id}>
                                {new Date(competition.date).toLocaleDateString("fr-FR")} - {competition.nom}
                            </option>
                        ))
                    }
                    </Form.Select>

                    <Form.Label>Epreuve</Form.Label>
                    <Form.Select aria-label="Default select example" id="epreuveSelect" value={newEpreuve} 
                        onChange={(event) => {handleSelectedEpreuve(event)}}>
                    <option hidden disabled selected value> -- Épreuve -- </option>
                    {
                        listeEpreuves.map((epreuve, index) => (
                            <option key={index} value={epreuve.label}>{epreuve.label}</option>
                        ))
                    }
                    </Form.Select>

                    <div id="minute">
                        <Form.Label>Temps</Form.Label>
                        <Row xs={12} lg={8}>
                            <Col xs={3} lg={2}>
                                <Form.Control id="minuteInput" type="number" defaultValue="00" min="0" max="59" value={minute} 
                    onChange={(event) => {setMinute(event.target.value)}}/>
                            </Col>
                            <Col xs={1} lg={1} className="ponctuationForm">:</Col>
                            <Col xs={3} lg={2}>
                                <Form.Control id="secondeInput" type="number" defaultValue="00" min="0" max="59" value={seconde} 
                    onChange={(event) => {setSeconde(event.target.value)}}/>
                            </Col>
                            <Col xs={1} lg={1} className="ponctuationForm">.</Col>
                            <Col xs={3} lg={2}>
                                <Form.Control id="centiemeInput" type="number" defaultValue="00" min="0" max="99" value={centieme} 
                    onChange={(event) => {setCentieme(event.target.value)}}/>
                            </Col>
                        </Row>
                    </div>

                    <div id="seconde">
                        <Form.Label>Temps</Form.Label>
                        <Row xs={12} lg={6}>
                            <Col xs={5} lg={2}>
                                <Form.Control id="secondeInput" type="number" defaultValue="00" min="0" max="59" value={seconde} 
                    onChange={(event) => {setSeconde(event.target.value)}}/>
                            </Col>
                            <Col xs={1} lg={1} className="ponctuationForm">.</Col>
                            <Col xs={5} lg={2}>
                                <Form.Control id="centiemeInput" type="number" defaultValue="00" min="0" max="99" value={centieme} 
                    onChange={(event) => {setCentieme(event.target.value)}}/>
                            </Col>
                        </Row>
                    </div>

                    <div id="distance">
                        <Form.Label>Distance</Form.Label>

                        <Row xs={12} lg={7}>
                            <Col xs={4} lg={2}>
                                <Form.Control type="number" defaultValue="00" min="0" max="99" value={metre} 
                    onChange={(event) => {setMetre(event.target.value)}}/>
                            </Col>
                            <Col xs={1} lg={1} className="ponctuationForm">,</Col>
                            <Col xs={4} lg={2}>
                                <Form.Control type="number" defaultValue="00" min="0" max="99" value={cm} 
                    onChange={(event) => {setCm(event.target.value)}}/>
                            </Col>
                            <Col xs={3} lg={2} className="ponctuationForm">mètre</Col>
                        </Row>
                    </div>

                    <Button variant="primary" type="submit" className="mt-3">
                    Ajouter performance
                    </Button>

                </Form>
            </Row>
        </Container>
    
)}

export default AddPerfComponent;