import React from 'react'
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, Spinner, Modal } from 'react-bootstrap';
import { UserContext } from '../../Utils/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretUp, faPen } from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { api, catchErrorAPI } from '../../api/apiConfig';
import imageCompression from 'browser-image-compression';

function TeamPage() {
  const { userAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [membres, setMembres] = useState(null);
  const [membreSelect, setMembreSelect] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    teams: []
  });

  

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Le prénom est requis'),
    lastName: Yup.string().required('Le nom est requis'),
    teams: Yup.array().min(1, 'Veuillez sélectionner au moins une équipe'),
  });


  const [photo, setPhoto] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [isLoading, setLoadingUpload] = useState(false);


  const [showModalAdd, setShowModalAdd] = useState(false);
  const handleCloseModalAdd = () => {
    setInitialValues({
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      teams: []
    });
    setShowModalAdd(false);
  }

  const handleShowModalAdd = (type) => {
    setTypeModal(type);
    setShowModalAdd(true);
  }



  useEffect(() => {
    document.title = "Nos équipes | UAC";

    fetchMembers();
  }, []);

  const fetchMembers = async () => {
        
    try{
      const response = await api.get('/member');
      setMembres(response.data);
      setLoading(false);
    }catch(err){
      catchErrorAPI(err);
    }
  }


  const addMember = async (values) => {

    const downloadUrl = await fileUploadHandler();

    const newMembreToCreate = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role,
      teams: values.teams,

      position: 99,

      lastEditUser: userAuth.email,
      lastEditTime: new Date(Date.now()).toLocaleString(),
      isArchived: false,
    }

    if (typeModal === "edit") {
      if(photo){
        newMembreToCreate.imgUrl = downloadUrl
      }

      const putTeam = async () => {
        try{
          await api.put(`/member/${membreSelect._id}`,newMembreToCreate);
          fetchMembers();
          setMessage({ error: false, msg: "Membre modifié !" });
        }catch(err){
          setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      putTeam();


    } else {
      newMembreToCreate.imgUrl = downloadUrl;
      const postMember = async () => {
        try{
          await api.post(`/member`,newMembreToCreate);
          fetchMembers();
          setMessage({ error: false, msg: "Membre ajouté !" });
        }catch(err){
          setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      postMember();
    }

    handleCloseModalAdd();
  }

  const fileSelectedHandler = async(e) => {
    setLoadingUpload(true);
    setPhoto(e.target.files[0]);
    await handleImageUpload(e.target.files[0])
  }

  async function handleImageUpload(imageFile) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setPhoto(compressedFile);
      console.log(compressedFile.size/1024/1024);
      setLoadingUpload(false);
    } catch (error) {
      console.log(error);
    }
  
  }

  const fileUploadHandler = async () => {
    if (photo) {
      const storage = getStorage();
      const idPhoto = Math.floor(10000000 + Math.random() * 90000000);
      const storageRef = ref(storage, `membres/${idPhoto}`);
      const uploadTask = uploadBytesResumable(storageRef, photo);

      // Créer une promesse qui sera résolue une fois que le téléchargement est terminé
      const downloadUrlPromise = new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgresspercent(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setPhoto(null);
              setImgUrl(downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });

      // Attendre que la promesse soit résolue avant de continuer
      const downloadUrl = await downloadUrlPromise;
      return downloadUrl;
    } else {
      return null;
    }
  };


  const handleUp = async (membre, index ) => {
    if (index > 0) {
      membre.position = membre.position -1;

      const putTeam = async () => {
        try{
          await api.put(`/member/${membre._id}`,membre);
          fetchMembers();
          setMessage({ error: false, msg: "Membre modifié !" });
        }catch(err){
          setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      putTeam();
    }
  }

  const handleEditMembre = (membre) => {
    setInitialValues({
      firstName: membre.firstName,
      lastName: membre.lastName,
      email: membre.email,
      role: membre.role,
      teams: membre.teams
    })

    setMembreSelect(membre);

    handleShowModalAdd("edit");
  }

  const handleDeleteMembre = async (_id) => {

    const deleteMembre = async () => {
      try{
        await api.delete(`/member/${_id}`);
        fetchMembers();
      }catch(err){
        catchErrorAPI(err);
      }
    }
    deleteMembre();
    handleCloseModalAdd();
  }




  if (loading) {
    return (
      <Container className="bloc">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </Container>
    )
  }

  return (
    <Container>
      <Row className="text-center pt-3">
        <Col>
          <h1 className="titleh1"><span className="jauneTitre">Nos équipes</span></h1>
        </Col>
      </Row>
      { userAuth && <Row>
        <Col><Button onClick={handleShowModalAdd}>Ajouter un membre</Button></Col>
      </Row>}
      <Row>
        <Col><p></p></Col>
      </Row>
      {
        ["Notre Comité", "Nos Coordinateurs", "Nos Entraineurs"].map(team => {
          return (
            <Row className="mx-1 p-2 bloc" key={team}>
              <Container className='px-0'>
                <Row className='pb-3'><h1 className="titleh1"><span className="jaune">{team}</span></h1></Row>
                <Row xs={1} md={3} lg={4}>
                  {
                    membres.filter(membre => membre.teams.includes(team)).map((membre, index) => {
                      return (
                        <Col className='pt-3 d-flex flex-wrap' key={index}>
                          <Card className='cardTeam flex-grow-1' key={membre._id}>
                            {membre.imgUrl && <Card.Img variant="top" className='cardImgTeam rounded' src={membre.imgUrl} />}
                            <Card.Body className='cardTeamBody'>
                              <Card.Title className='titleTeamMember'>{membre.firstName} {membre.lastName}</Card.Title>
                              <Card.Text className='bodyTeam'>
                                {team !== "Nos Entraineurs" && <span>{membre.role}<br /></span>}
                                {membre.email && <span className='mailMember'>{membre.email}
                                </span>}
                                {userAuth &&
                                  <div>
                                    { team === "Notre Comité" && <FontAwesomeIcon className='spaceIconeRight' icon={faCaretUp} onClick={() => handleUp(membre ,index)} />}
                                     
                                    <FontAwesomeIcon icon={faPen} onClick={() => handleEditMembre(membre)} />
                                  </div>}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Container>
            </Row>
          )
        })
      }


      <Modal show={showModalAdd} onHide={handleCloseModalAdd} backdrop="static"
        keyboard={false} fullscreen={false} >
        <Modal.Header className='headerModal'>
          <Modal.Title className='titleModal'>

            {typeModal === "edit" ? <span>Modifier un membre</span> : <span>Ajouter un membre</span>}
          </Modal.Title>
          <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalAdd} />
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Container>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addMember(values);

              }}
            >
                {({ values, errors, touched }) => (
              <Form>
                <label htmlFor="firstName">Prénom</label> <br />
                <Field id="firstName" name="firstName" /><br />
                

                <label htmlFor="lastName">Nom</label><br />
                <Field id="lastName" name="lastName" /><br />

                <label htmlFor="email">Email</label><br />
                <Field
                  id="email"
                  name="email"
                  type="email"
                /><br />

                <label htmlFor="role">Fonction</label><br />
                <Field id="lastName" name="role" /><br />

                <div id="team-group">Équipe(s)</div>
                <div role="group" aria-labelledby="team-group">
                  <label>
                    <Field type="checkbox" name="teams" value="Notre Comité" />
                    Comité
                  </label>
                  <label>
                    <Field type="checkbox" name="teams" value="Nos Coordinateurs" />
                    Coordination
                  </label>
                  <label>
                    <Field type="checkbox" name="teams" value="Nos Entraineurs" />
                    Entraineurs
                  </label>
                </div>


                <input type='file' accept=".jpg,.png,.jpeg" onChange={fileSelectedHandler} />
                <br/>
                <ErrorMessage name="firstName" /><br/>
                <ErrorMessage name="lastName" /><br/>
                <ErrorMessage name="teams" /><br/>


                {typeModal !== "edit" ? <Button variant="primary" type="submit" className="mt-3" disabled={isLoading}
          >
              
                    {isLoading ? 'Chargement...' : 'Ajouter'}
                  </Button> : <div>

                  <Button variant="light" className="mt-3 me-2" onClick={() => handleDeleteMembre(membreSelect._id)}>
                    Supprimer membre
                  </Button>
                  <Button variant="success" type="submit" className="mt-3" >
                    Modifier membre
                  </Button>
                </div>}
              </Form>)}
            </Formik>

          

          </Container>
        </Modal.Body>
      </Modal>


    </Container>
  )
}

export default TeamPage