import { Container, Button, Col, Row, Spinner, Form, Card, ListGroup, ListGroupItem, Modal, Alert, Accordion } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { getLabelCat } from "../../Utils/utilis";
import { UserContext } from "../../Utils/userContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faPen, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
//import { dataJsonExport } from "../../exportJson";
import { api, catchErrorAPI } from "../../api/apiConfig";



function TabTopClub({ sexe, saison, categorie }) {
    const { userAuth, setUserAuth } = useContext(UserContext);

    const [tops, setTops] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    const [newEpreuve, setNewEpreuve] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [showModalEpreuve, setShowModalEpreuve] = useState(false);

    const [epreuveSelect, setEpreuveSelect] = useState();
    const [perfSelect, setPerfSelect] = useState();
    const [typeModal, setTypeModal] = useState();


    const [newPerfResult, setNewPerfResult] = useState("");
    const [newPerfAthlete, setNewPerfAthlete] = useState("");
    const [newPerfAnnee, setNewPerfAnnee] = useState("");

    const [editedEpreuveLabels, setEditedEpreuveLabels] = useState({});

    const handleCloseModalEpreuve = () => {
        setShowModalEpreuve(false);
    }

    const handleShowModalEpreuve = (epreuveSelect, type) => {
        setShowModalEpreuve(true);
    }

    const handleCloseModal = () => {
        setNewPerfResult("");
        setNewPerfAthlete("");
        setNewPerfAnnee(null);

        setShowModal(false);
    }

    const handleShowModal = (epreuveSelect, type) => {
        setEpreuveSelect(epreuveSelect);
        setTypeModal(type);
        setShowModal(true);
    }


    useEffect(() => {
        document.title = "Top 10 " + saison + " " + categorie + " " + sexe + " | UAC";

        fetchTops();

    }, [sexe, saison, categorie]);

    const fetchTops = async () => {
        try {
            const response = await api.get(`/topsclub/${saison}/${sexe}/${categorie}`);
            setTops(response.data);
            
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }

    const addEpreuve = async (e) => {
        e.preventDefault();

        const newEpreuveToCreate = {
            categorie: categorie,
            sexe: sexe,
            saison: saison,
            epreuveLabel: newEpreuve,
            position: 99,
            performances: [],
            lastEditUser: userAuth.email,
            lastEditTime: new Date(Date.now()).toLocaleString(),
            isArchived: false,
        }

        const postEpreuve = async () => {
            try {
                await api.post(`/topsclub`, newEpreuveToCreate);
                fetchTops();
                setMessage({ error: false, msg: "Épreuve ajoutée !" });
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
                catchErrorAPI(err);
            }
        }
        postEpreuve();

    }

    const addPerfInEpreuve = async (e) => {
        e.preventDefault();
        

        if (typeModal === "edit") {
            epreuveSelect.performances[perfSelect].resultat = newPerfResult;
            epreuveSelect.performances[perfSelect].athlete = newPerfAthlete;
            epreuveSelect.performances[perfSelect].annee = newPerfAnnee;

            epreuveSelect.lastEditUser = userAuth.email;
            epreuveSelect.lastEditTime = new Date(Date.now()).toLocaleString();

            putTop(epreuveSelect);

        } else {

            const newPerfToAdd = {
                resultat: newPerfResult,
                athlete: newPerfAthlete,
                annee: newPerfAnnee
            }
            epreuveSelect.lastEditUser = userAuth.email;
            epreuveSelect.lastEditTime = new Date(Date.now()).toLocaleString();
            epreuveSelect.performances.push(newPerfToAdd);

           putTop(epreuveSelect);

        }

        handleCloseModal();

    }

    const handleEditPef = (element, index, resultat, athlete, annee) => {
        setNewPerfResult(resultat);
        setNewPerfAthlete(athlete);
        setNewPerfAnnee(annee);
        setPerfSelect(index);

        handleShowModal(element, "edit");
    }

    const handleDeletePerf = async () => {
        if (perfSelect != null && epreuveSelect != null) {

            epreuveSelect.lastEditUser = userAuth.email;
            epreuveSelect.lastEditTime = new Date(Date.now()).toLocaleString();
            epreuveSelect.performances.splice(perfSelect, 1);

            putTop(epreuveSelect);

            handleCloseModal();

        }
    }

    const handleUp = async (epreuve, indexPerf) => {

        if (indexPerf > 0) {
            let temp = epreuve.performances[indexPerf - 1];
            epreuve.performances[indexPerf - 1] = epreuve.performances[indexPerf];
            epreuve.performances[indexPerf] = temp;

            epreuve.lastEditUser = userAuth.email;
            epreuve.lastEditTime = new Date(Date.now()).toLocaleString();

            putTop(epreuve)
        }
    }

    const archiveEpreuve = async (epreuve) => {
        epreuve.isArchived = true;
        epreuve.lastEditUser = userAuth.email;
        epreuve.lastEditTime = new Date(Date.now()).toLocaleString();


        putTop(epreuve);
    }

    const putTop = async (epreuve) => {
        try {
            await api.put(`/topsclub/${epreuve._id}`, epreuve);
            fetchTops();
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const editLabelEpreuve = async (epreuve) => {
        // Faites quelque chose avec la valeur éditée, par exemple :

        epreuve.epreuveLabel = editedEpreuveLabels[epreuve._id]
        epreuve.lastEditUser = userAuth.email;
        epreuve.lastEditTime = new Date(Date.now()).toLocaleString();

        putTop(epreuve);

        const newEditedEpreuveLabels = { ...editedEpreuveLabels };
        delete newEditedEpreuveLabels[epreuve._id];
        setEditedEpreuveLabels(newEditedEpreuveLabels);
    };

    const handleInputChange = (event, element) => {
        const newEditedEpreuveLabels = { ...editedEpreuveLabels };
        newEditedEpreuveLabels[element._id] = event.target.value;
        setEditedEpreuveLabels(newEditedEpreuveLabels);
    };

    const upEpreuve = async (index, epreuve) => {
        if (index > 0) {
            let positionPrevius = tops[index - 1].position;

            epreuve.position = positionPrevius - 0.5;
            epreuve.lastEditUser = userAuth.email;
            epreuve.lastEditTime = new Date(Date.now()).toLocaleString();


            putTop(epreuve);
        }
    }

    /*
    const importEpreuve = async => {
        const topCollectionRef = collection(db, "topsClub");

        let dataJson = dataJsonExport;

        dataJson.forEach(async (epreuve, index) => {
            epreuve.position = index;
            try {
                await addDoc(topCollectionRef, epreuve);
                console.log("Import OK");
            } catch (error) {
                console.log(error);
            }
        });

    }*/

    return (
        <Container>
            <Row>
                <Col className="px-0">
                    <h1 className="titleh1">{getLabelCat(categorie)} {sexe}</h1>
                </Col>
            </Row>
            <Row className='py-3' >
                {/*
                    tops && tops.map(element => {
                        return (
                            <Col className='py-3' >
                                <Card >
                                    <Card.Header id="cardTop" className="titreTop">{element.epreuveLabel} {userAuth &&
                                        <Button variant="secondary" size="sm" onClick={() => handleShowModal(element, "add")}
                                        >Ajout</Button>
                                    }</Card.Header>
                                    <ListGroup className="listeTop" variant="flush">
                                        {element.performances.map((perf, index) => {
                                            return (
                                                <ListGroupItem key={index} className="listeTop-item" >
                                                    <Container>
                                                        <Row>
                                                            <FontAwesomeIcon icon={faCaretUp} onClick={() => handleUp(element, index)} />
                                                            <Col xs={1}>{index + 1}</Col>
                                                            <Col xs={3}>{perf.resultat}</Col>
                                                            <Col xs={5}>{perf.athlete}</Col>
                                                            <Col xs={3}>{perf.annee}
                                                                <FontAwesomeIcon icon={faPen} onClick={() => handleEditPef(element, index, perf.resultat, perf.athlete, perf.annee)} />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </ListGroupItem>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                            </Col>
                        )
                    })
                */}
                <Col className="px-0">
                    <Accordion>
                        {
                            tops && tops.map(element => {
                                return (

                                    <Accordion.Item eventKey={element._id} key={element._id}>
                                        <Accordion.Header>{element.epreuveLabel} {userAuth &&
                                            <Button variant="primary" size="sm" onClick={() => handleShowModal(element, "add")}
                                            ><FontAwesomeIcon icon={faPlus} /></Button>
                                        }</Accordion.Header>
                                        <Accordion.Body className="px-0">
                                            <ListGroup className="listeTop" variant="flush">
                                                {element.performances.map((perf, index) => {
                                                    return (
                                                        <ListGroupItem key={index} className="listeTop-item px-2" >
                                                            <Container className="px-0">
                                                                <Row>

                                                                    <Col xs={1}>{index + 1}</Col>
                                                                    <Col xs={3}>{perf.resultat}</Col>
                                                                    <Col xs={5}>{perf.athlete}</Col>

                                                                    {userAuth &&
                                                                        <Col xs={2}>{perf.annee}</Col>
                                                                    }
                                                                    {!userAuth &&
                                                                        <Col xs={3}>{perf.annee}</Col>
                                                                    }
                                                                    {userAuth &&
                                                                        <Col xs={1}>
                                                                            <FontAwesomeIcon icon={faCaretUp} onClick={() => handleUp(element, index)} /> {` `}
                                                                            <FontAwesomeIcon icon={faPen} onClick={() => handleEditPef(element, index, perf.resultat, perf.athlete, perf.annee)} />
                                                                        </Col>}


                                                                </Row>
                                                            </Container>
                                                        </ListGroupItem>
                                                    )
                                                })}
                                            </ListGroup>

                                        </Accordion.Body>
                                    </Accordion.Item>

                                )
                            })}
                    </Accordion>
                </Col>
            </Row>
            <Row>
                <Col className="mx-5">
                    {message?.msg && (
                        <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                            {message?.msg}
                        </Alert>)
                    }
                    {userAuth &&
                        <div>
                            <Form onSubmit={addEpreuve}>
                                <Form.Label>Épreuve : </Form.Label>
                                <Form.Control type="text" onChange={(event) => { setNewEpreuve(event.target.value) }} value={newEpreuve} required />
                                <Button className="mt-2" variant="primary" type="submit" >Ajouter une épreuve</Button>
                            </Form>
                            <Button className="mt-2" variant="secondary" onClick={handleShowModalEpreuve}>Modifier l'ordre</Button>
                        </div>

                    }
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static"
                keyboard={false} fullscreen={false} >
                <Modal.Header className='headerModal'>
                    <Modal.Title className='titleModal'>
                        {typeModal === "add" ? <span>Ajouter une performance au {epreuveSelect.epreuveLabel}</span> : <span>Modifier une performance</span>}
                    </Modal.Title>
                    <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <Container>
                        <Form onSubmit={addPerfInEpreuve}>
                            <Row className="mb-3">
                                <Form.Label>Résultat</Form.Label>
                                <Form.Control type="text" value={newPerfResult}
                                    onChange={(event) => { setNewPerfResult(event.target.value) }} required />
                                <Form.Text className="text-muted">
                                    02'30''21 ou 6.85m
                                </Form.Text>
                            </Row>

                            <Row className="mb-3">
                                <Form.Label>Athlète</Form.Label>
                                <Form.Control type="text" value={newPerfAthlete}
                                    onChange={(event) => { setNewPerfAthlete(event.target.value) }} required />
                                <Form.Text className="text-muted">
                                    Prénom Nom
                                </Form.Text>
                            </Row>

                            <Row className="mb-3">
                                <Form.Label>Année</Form.Label>
                                <Form.Control type="text" value={newPerfAnnee}
                                    onChange={(event) => { setNewPerfAnnee(event.target.value) }} required />

                            </Row>

                            <Row className="d-flex justify-content-between">
                                {typeModal === "add" ? <Button variant="primary" type="submit" className="mt-3">
                                    Ajouter performance
                                </Button> : <div>

                                    <Button variant="light" className="mt-3 me-2" onClick={handleDeletePerf}>
                                        Supprimer performance
                                    </Button>
                                    <Button variant="success" type="submit" className="mt-3" >
                                        Modifier performance
                                    </Button>
                                </div>}
                            </Row>

                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={showModalEpreuve} onHide={handleCloseModalEpreuve} backdrop="static"
                keyboard={false} fullscreen={false} >
                <Modal.Header className='headerModal'>
                    <Modal.Title className='titleModal'>
                        Epreuves
                    </Modal.Title>
                    <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalEpreuve} />
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <Container>
                        {tops.map((element, index) => (
                            <Row key={element._id} >
                                <Form className="my-2">
                                    <Form.Control
                                        type="text"
                                        className="mb-2"
                                        value={editedEpreuveLabels[element._id] !== undefined ? editedEpreuveLabels[element._id] : element.epreuveLabel}
                                        required
                                        onChange={(event) => handleInputChange(event, element)} // gérer le changement de valeur
                                    />
                                    <Button
                                        variant="warning"
                                        className="m-2"
                                        onClick={() => editLabelEpreuve(element)}
                                    >
                                        Modifier titre
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="m-2"
                                        onClick={() => upEpreuve(index, element)}
                                    >
                                        <FontAwesomeIcon icon={faCaretUp} />
                                    </Button>

                                    <Button
                                        variant="danger"
                                        onClick={() => archiveEpreuve(element)}
                                        className="m-2"
                                    >
                                        Archiver
                                    </Button>
                                </Form>
                                <hr />
                            </Row>

                        ))}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModalEpreuve}>Confimer</Button>
                </Modal.Footer>
            </Modal>
            {/* <Button onClick={importEpreuve}>
                Importer
            </Button>
            */ }

        </Container>
    )
}

export default TabTopClub;