import { Container, Row, Card, Col, Spinner, Badge, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import { getButtonColor } from "../../Utils/utilis";
import { useNavigate } from "react-router-dom";
import { api, catchErrorAPI } from "../../api/apiConfig";

function ListCompetititon() {

  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const [competitions, setCompetitons] = useState([]);

  useEffect(() => {
    document.title = "Compétitions | UAC";

    fetchCompt();
  }, [])

  const fetchCompt = async () => {
        
    try{
      const response = await api.get('/competitions/latest/all');
      setCompetitons(response.data);
      setLoading(false);
    }catch(err){
      catchErrorAPI(err);
    }
  }

  if (loading) {
    return (
      <Container className="bloc">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </Container>
    )
  }

  return (
    <Container className="bloc">
      <Row className="text-center">
        <h1 className="titleHomePage"><span className="jaune"><FontAwesomeIcon icon={faFlag} /> Compétitions</span></h1>
      </Row>
      
      {
            <Row xs={1} md={1} lg={1}>
            {
                competitions.map(compet => {
                  return(
                    <Col className='pt-3 d-flex flex-wrap'>
                      <Card className="flex-grow-1">
                        <Card.Body>
                          <Row>
                            <Col xs={12} sm={8}>
                              <Card.Title>{new Date(compet.date).toLocaleDateString("fr-FR")} - {compet.nom} <Badge bg={getButtonColor(compet.saison)}>{compet.saison}</Badge></Card.Title>
                            </Col>
                            <Col xs={12} sm={4} className="text-end">
                              {compet.hasPerformance && <Button variant="primary" size="sm" onClick={() => {navigate(`/competition/${compet._id}`)}}>Résultats</Button>}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>}
    </Container>
  )
}

export default ListCompetititon;