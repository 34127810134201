import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import {
    Routes,
    Route,
    useNavigate
} from "react-router-dom";
import GestionAthletePage from "./GestionAthletePage";
import GestionCommunicationPage from "./GestionCommunicationPage";
import GestionCompetionPage from "./GestionCompetitionPage";
import { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import UserList from "./UserList";
import LogPage from "./LogPage";
import GestionEventPage from "./GestionEventPage";
import EventListRegistration from "../Event/Event/EventListRegistration";
import CompListRegistration from "../Event/Competition/CompListRegistration";
import CompetTabPage from "../Event/Competition/CompetTabPage";
import LoginPage from "./LoginPage";
import ListAthletesArchived from "./ListAthletesArchived";
import GestionDiscipline from "./GestionDiscipline";
import StageRegistrationPage from "../Event/Stage/StageRegistrationPage";
import StageListRegistration from "../Event/Stage/StageListRegistration";




function AdminHomePage() {
    let navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        document.title = "Admin | UAC";
    }, []);

    return (
        <Container>
            <Row className='py-3'>
                <h1 className="text-center">Administration</h1>
            </Row>
            <Row className='py-3'>
                <Col sm={2} className="bloc">
                    <b>Menu Admin</b>
                    <Navbar expand="xl" className="p-0" variant="light">
                        <Navbar.Toggle
                            aria-controls="admin-navbar"
                            onClick={toggleNavbar}
                        />
                        <Navbar.Collapse id="admin-navbar" className={expanded ? 'show' : ''}>
                            <Nav defaultActiveKey="/admin/athlete" variant="pills" className="flex-column">
                                <Nav.Link onClick={() => navigate("/admin/athlete")} >Gestion athlète</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/dashboard")} >Statistiques</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/communication")} >Gestion communication</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/competiton")} >Gestion compétition</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/users")} >Gestion utilisateurs</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/events")} >Gestion évenements</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/disciplines")} >Gestion disciplines</Nav.Link>
                                <Nav.Link onClick={() => navigate("/admin/login")} >Déconnexion</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
                <Col sm={10}>
                    <Routes>
                        <Route path='athlete' element={<GestionAthletePage type="add" />} />
                        <Route path='athlete/archived' element={<ListAthletesArchived />} />
                        <Route path='communication' element={<GestionCommunicationPage />} />
                        <Route path='competiton' element={<GestionCompetionPage />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='users' element={<UserList />} />
                        <Route path='log' element={<LogPage />} />
                        <Route path='events' element={<GestionEventPage />} />
                        <Route path='disciplines' element={<GestionDiscipline />} />
                        <Route path='login' element={<LoginPage />} />
                        <Route path='eventRegistration/:idEvent' element={<EventListRegistration />} />
                        <Route path='compRegistration/:idEvent' element={<CompListRegistration />} />
                        <Route path='stageRegistration/:idEvent' element={<StageListRegistration />} />
                        <Route path='competTabPage/:idEvent' element={<CompetTabPage />} />
                    </Routes>
                </Col>
            </Row>


        </Container>
    )
}

export default AdminHomePage;