import React from 'react'
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, Spinner, Modal } from 'react-bootstrap';
import { UserContext } from '../../Utils/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faAnglesRight, faPen, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import imageCompression from 'browser-image-compression';
import {
  useNavigate
} from "react-router-dom";
import { api, catchErrorAPI } from '../../api/apiConfig';



function NewsPage() {


  const { userAuth, setUserAuth } = useContext(UserContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoading, setLoadingUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [news, setNews] = useState([]);
  const [actuSelect, setActuSelect] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [initialValues, setInitialValues] = useState({
    titre: '',
    contenu: '',
    dateCreation: '',
    auteur: '',
    type: '',
    labelLink: '',
    urlLink: ''
  });



  const validationSchema = Yup.object().shape({
    titre: Yup.string().required('Un titre est requis'),
    auteur: Yup.string().required('Un auteur est requis'),
    type: Yup.string().required('Un type est requis'),
  });


  const [photo, setPhoto] = useState(null);
  const [extension, setExtention] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);


  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalNews, setShowModalNews] = useState(false);
  const handleCloseModalAdd = () => {
    setInitialValues({
      titre: '',
      contenu: '',
      auteur: '',
      type: '',
      labelLink: '',
      urlLink: ''
    });
    setShowModalAdd(false);
  }

  const handleShowModalAdd = (type) => {
    setTypeModal(type);
    setShowModalAdd(true);
  }

  const handleCloseModalNews = () => {
    setShowModalNews(false);
  }

  const handleShowModalNews = (actu) => {

    setActuSelect(actu);
    setShowModalNews(true);
  }

  const handleEditNews = (actu) => {
    setInitialValues({
      titre: actu.titre,
      contenu: actu.contenu,
      auteur: actu.auteur,
      type: actu.type,
      labelLink: actu.labelLink,
      urlLink: actu.urlLink
    })

    setActuSelect(actu);

    handleShowModalAdd("edit");
  }

  useEffect(() => {
    document.title = "Actualités | UAC";

    fetchNews();
  }, [showModalAdd])

  const fetchNews = async () => {

    try {
      const response = await api.get('/news');
      setNews(response.data);
      setLoading(false);
    } catch (err) {
      catchErrorAPI(err);
    }
  };

  const addNews = async (values) => {
    const downloadUrl = await fileUploadHandler();

    const newNewsToCreate = {
      titre: values.titre,
      contenu: values.contenu,
      auteur: values.auteur,
      type: values.type,
      labelLink: values.labelLink,
      urlLink: values.urlLink,

      lastEditUser: userAuth.email,
      lastEditTime: new Date(Date.now()).toLocaleString(),
    }

    if (photo) {
      newNewsToCreate.imgUrl = downloadUrl;
      newNewsToCreate.extension = extension;
    }

    if (typeModal === "edit") {

      const putNews = async () => {
        try {
          await api.put(`/news/${actuSelect._id}`, newNewsToCreate);
          handleCloseModalAdd();
          setMessage({ error: false, msg: "Membre modifié !" });
        } catch (err) {
          setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      putNews();

    } else {
      newNewsToCreate.dateCreation = new Date(Date.now());

      const postNews = async () => {
        try {
          await api.post(`/news`, newNewsToCreate);
          await fetchNews();
          setMessage({ error: false, msg: "Membre ajouté !" });
        } catch (err) {
          setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      postNews();

      handleCloseModalAdd();
    }
  }

  async function handleImageUpload(imageFile) {
    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1920
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setPhoto(compressedFile);
      //console.log(compressedFile.size/1024/1024);
      setLoadingUpload(false);
    } catch (error) {
      console.log(error);
    }

  }


  const fileSelectedHandler = async (e) => {
    setLoadingUpload(true);
    setPhoto(e.target.files[0]);



    const fileExtension = e.target.files[0].name.split('.').pop();
    setExtention(fileExtension);

    if (fileExtension != "pdf") {

      await handleImageUpload(e.target.files[0])
    } else {
      setLoadingUpload(false);
    }
  }

  const fileUploadHandler = async () => {
    if (photo) {
      const storage = getStorage();
      const idPhoto = Math.floor(10000000 + Math.random() * 90000000);
      const storageRef = ref(storage, `news/${idPhoto}`);
      const uploadTask = uploadBytesResumable(storageRef, photo);

      // Créer une promesse qui sera résolue une fois que le téléchargement est terminé
      const downloadUrlPromise = new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgresspercent(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setPhoto(null);
              setImgUrl(downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });

      // Attendre que la promesse soit résolue avant de continuer
      const downloadUrl = await downloadUrlPromise;
      return downloadUrl;
    } else {
      return null;
    }
  };


  const handleDeleteActu = async (id) => {

    const deleteNews = async () => {
      try {
        await api.delete(`/news/${id}`);
        await fetchNews();
      } catch (err) {
        catchErrorAPI(err);
      }
    }
    deleteNews();
    handleCloseModalAdd();

  }





  if (loading) {
    return (
      <Container className="bloc">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </Container>
    )
  }


  return (
    <Container>
      <Row className="text-center pt-3">
        <Col>
          <h1 className="titleh1"><span className="jauneTitre">Actualités du club</span></h1>
        </Col>
      </Row>
      {userAuth && <Row>
        <Col><Button onClick={handleShowModalAdd}>Ajouter une actualité</Button></Col>
      </Row>}
      <Row>
        <Col><p></p></Col>
      </Row>
      {
        <Container>

          <Row xs={1} md={3} lg={3}>
            {news && news.length > 0 && (
              news.map((actu) => {
                return (
                  <Col className='pt-3 d-flex flex-wrap' key={actu._id}>
                    <Card className='flex-grow-1'>

                      {actu.imgUrl && actu.extension != "pdf" ? <Card.Img variant="top" className="cardImgNews" src={actu.imgUrl} onClick={() => { navigate(`/actualites/${actu._id}`) }}/> : <Card.Img variant="top" className="cardImgNews" src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/news%2F69014975?alt=media&token=514f6a05-3698-4010-8dcc-9d2c053cf378" onClick={() => { navigate(`/actualites/${actu._id}`) }}/>}

                      <Card.Body onClick={() => { navigate(`/actualites/${actu._id}`) }}>
                        <Card.Title >{actu.titre} <FontAwesomeIcon icon={faAnglesRight} className="iconTransparence" /> </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{new Date(actu.dateCreation).toLocaleDateString("fr-FR")}</Card.Subtitle>
                        <Card.Text >
                          {actu.contenu.substring(0, 100)}{actu.contenu.length > 100 ? "..." : ""}

                        </Card.Text>

                      </Card.Body>
                      {userAuth &&
                        <div>

                          <FontAwesomeIcon icon={faPen} onClick={() => handleEditNews(actu)} />
                        </div>}
                    </Card>
                  </Col>
                )
              })
            )}
          </Row>
        </Container>
      }


      <Modal show={showModalAdd} onHide={handleCloseModalAdd} backdrop="static"
        keyboard={false} fullscreen={false} >
        <Modal.Header className='headerModal'>
          <Modal.Title className='titleModal'>

            {typeModal === "edit" ? <span>Modifier une actualité</span> : <span>Ajouter une actualité</span>}
          </Modal.Title>
          <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalAdd} />
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Container>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {

                addNews(values);

              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <label htmlFor="titre">Titre</label> <br />
                  <Field id="titre" name="titre" className="fieldTitleNews" /><br />


                  <label htmlFor="contenu">Contenu</label><br />
                  <Field id="contenu" name="contenu" as="textarea" component="textarea" className="fieldContentNews" /><br />

                  <label htmlFor="auteur">Auteur</label><br />
                  <Field name="auteur" as="select">
                    <option value="" disabled> -- Auteur -- </option>
                    <option value="Président">Président</option>
                    <option value="Vice-président">Vice-président</option>
                    <option value="Secrétaire">Secrétaire</option>
                    <option value="Comité">Comité</option>
                    <option value="Coordinateur EDJ">Coordinateur EDJ</option>
                    <option value="Staff technique">Staff technique</option>
                    <option value="Entraineur">Entraineur</option>
                  </Field> <br />


                  <label htmlFor="type">Type d'actualité</label><br />
                  <Field name="type" as="select">
                    <option value="" disabled> -- Type -- </option>
                    <option value="Information générale">Information générale</option>
                    <option value="École des jeunes">École des jeunes</option>
                    <option value="Résultat sportif">Résultat sportif</option>
                    <option value="Évenement">Évenement</option>
                    <option value="Jogging">Jogging</option>
                    <option value="Autre">Autre</option>
                  </Field><br /><br />

                  <label htmlFor="urlLink">URL du lien (facultatif)</label> <br />
                  <Field id="urlLink" name="urlLink" className="fieldTitleNews" /><br />

                  <label htmlFor="labelLink">Titre du lien (facultatif)</label> <br />
                  <Field id="labelLink" name="labelLink" className="fieldTitleNews" /><br /><br />

                  <label>Image ou pdf (facultatif)</label> <br />
                  <input type='file' accept=".jpg,.png,.jpeg,.pdf" onChange={fileSelectedHandler} />
                  <br />
                  <div>
                    <p><ErrorMessage name="titre" /></p>
                    <p><ErrorMessage name="auteur" /></p>
                    <p><ErrorMessage name="type" /></p>
                  </div>


                  {typeModal !== "edit" ? <Button variant="primary" type="submit" className="mt-3" disabled={isLoading}
                  >

                    {isLoading ? 'Chargement...' : 'Ajouter'}
                  </Button> : <div>

                    <Button variant="light" className="mt-3 me-2" onClick={() => handleDeleteActu(actuSelect._id)}>
                      Supprimer
                    </Button>
                    <Button variant="success" type="submit" className="mt-3" >
                      Modifier
                    </Button>
                  </div>}
                </Form>)}
            </Formik>



          </Container>
        </Modal.Body>
      </Modal>

      {actuSelect &&
        <Modal size='lg' show={showModalNews} onHide={handleCloseModalNews} backdrop="static"
          keyboard={false} fullscreen={false} >
          <Modal.Header className='headerModal'>

            <Modal.Title className='titleModal'>
              {actuSelect.titre}
            </Modal.Title>
            <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalNews} />
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <Container>
              <Row>{new Date(actuSelect.dateCreation.seconds * 1000).toLocaleDateString("fr-FR")} - {actuSelect.type} - {actuSelect.auteur}</Row>
              <Row><hr /></Row>
              <Row className='mb-3' style={{ whiteSpace: "pre-line" }}>{actuSelect.contenu}</Row>

              {actuSelect.urlLink && <Row className='m-4 px-3'><Button href={actuSelect.urlLink} target="_blank">{actuSelect.labelLink ? actuSelect.labelLink : 'Lien'}</Button></Row>}



              {actuSelect.imgUrl &&
                <div>{actuSelect.extension === "pdf" ?
                  <Row className='m-4 px-3'>
                    <Button href={actuSelect.imgUrl} target="_blank"><FontAwesomeIcon className='iconPDF' icon={faArrowDown} />PDF</Button> </Row> : <Row className='p-3'><img src={actuSelect.imgUrl} /></Row>}</div>
              }

            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { navigate(`/actualites/${actuSelect.id}`) }}>
              Partager
            </Button>
            <Button variant="secondary" onClick={handleCloseModalNews}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>}


    </Container>
  )
}

export default NewsPage