import React, { useEffect, useState } from 'react'
import {Container, Button, ButtonGroup, Spinner, Row, Nav} from 'react-bootstrap'
import { api, catchErrorAPI } from '../../api/apiConfig';

const SeasonSelector = ({ setSelectedSeason }) => {

    const [loading, setLoading] = useState(true);
    const [seasons, setSeasons] = useState();
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try{
              const response = await api.get('/seasons');
              const currentSeason = await api.get('/currentSeason');
              setSeasons(response.data);
              setActiveKey(currentSeason.data.realSeason);
              setLoading(false);
            }catch(err){
              catchErrorAPI(err);
            }
          }
          fetchData();
    }, [])

    const handleSeasonChange = (season) => {
        setSelectedSeason(season);
        setActiveKey(season);
      };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container className="bloc p-0">
            <Nav id="NavGroup" variant="pills"  activeKey={activeKey} onSelect={(key) => {
                    setActiveKey(key);
                    handleSeasonChange(key);
                }} className="flex-nowrap p-3">
                    {seasons.map(s => { return (
                        <Nav.Item className="groupe_navitem"  key={s.realSeason}>
                            <Nav.Link className="mb-0" eventKey={s.realSeason} >
                                {s.label}
                            </Nav.Link>
                        </Nav.Item>
                    )})}
            </Nav>
        </Container>
    )
}

export default SeasonSelector