import React, { useEffect, useState } from 'react'
import { Container, Spinner, Table, Badge } from 'react-bootstrap'
import { api, catchErrorAPI } from '../../../api/apiConfig';
import { useNavigate, useParams } from 'react-router-dom';

const CompetTabPage = () => {

    const { idEvent } = useParams();
    const [registrations, setRegistrations] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get(`/disciplineRegistrations/${idEvent}`);

            setRegistrations(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const getBadgeVariant = (status) => {
        switch (status) {
            case 'registered':
                return 'warning';
            case 'paid':
                return 'success';
            case 'present':
                return 'primary';
            case 'canceled':
                return 'danger';
            default:
                return '';
        }
    };

    const getBadgeLabel = (status) => {
        switch (status) {
            case 'registered':
                return 'Inscrit';
            case 'paid':
                return 'Payé';
            case 'present':
                return 'Présent';
            case 'canceled':
                return 'Annulé';
            default:
                return '';
        }
    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }



    return (
        <Container>

<Table striped bordered hover>
    <thead>
        <tr>
            
            <th>Genre</th>
            <th>Categorie</th>
            <th>Nom</th>
            <th>Prenom</th>
            <th>Club</th>
            <th>Dossard</th>
            <th>Performance</th>
        </tr>
    </thead>
    <tbody>
        {registrations.map((r, index) => (
            <React.Fragment key={r._id}>
                {index === 0 || r.discipline !== registrations[index - 1].discipline ? (
                    <tr>
                        <td colSpan="7"> {/* Colspan 7 pour fusionner toutes les colonnes */}
                            <strong>{r.discipline}</strong>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    
                    <td>{r.idRegistration.sexe}</td>
                    <td>{r.idRegistration.category}</td>
                    <td>{r.idRegistration.lastName}</td>
                    <td>{r.idRegistration.firstName}</td>
                    <td>{r.idRegistration.club}</td>
                    <td>{r.idRegistration.dossard}</td>
                    <td>
                        {r.performance} <Badge bg={getBadgeVariant(r.idRegistration.status)}>{getBadgeLabel(r.idRegistration.status)}</Badge>
                    </td>
                </tr>
            </React.Fragment>
        ))}
    </tbody>
</Table>


        </Container>
    )
}

export default CompetTabPage