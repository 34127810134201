import { Container, Row, Col, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { api, catchErrorAPI } from "../../api/apiConfig";




function Dashboard() {

    const [nbHommes, setNbHommes] = useState(0);
    const [nbFemmes, setNbFemmes] = useState(0);

    const [dataCategory, setDataCategory] = useState([]);


    ChartJS.register(ArcElement, Tooltip, Legend);

    const dataHF = {
        labels: ['Homme', 'Femme'],
        datasets: [
            {
                label: 'Nombre d\'athlètes',
                data: [nbHommes, nbFemmes],
                backgroundColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    function getNumber(category) {

        const nbCategory = dataCategory.find(obj => obj._id === category);
      
        return nbCategory ? nbCategory.count : 0;
      }

    const dataCatH = {
        labels: ['Cadet H', 'Scolaire H', 'Junior H', 'Senior H', 'Master H'],
        datasets: [
            {
                label: 'Nombre d\' athlètes',
                data: [getNumber('Cadet H'), getNumber('Scolaire H'), getNumber('Junior H'), getNumber('Senior H'), getNumber('Master H')],
                backgroundColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataCatF = {
        labels: ['Cadet F', 'Scolaire F', 'Junior F', 'Senior F', 'Master F'],
        datasets: [
            {
                label: 'Nombre d\' athlètes',
                data: [getNumber('Cadet F'), getNumber('Scolaire F'), getNumber('Junior F'), getNumber('Senior F'), getNumber('Master F')],
                backgroundColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };






    useEffect(() => {
        const countAthlete = async () => {
            try {
                const response = await api.get('/dashboard/nbAthetes');

                setNbHommes(response.data.nbHommes);
                setNbFemmes(response.data.nbFemmes);

            } catch (err) {
                catchErrorAPI(err);
            }
        };

        const countAthleteByCategory = async () => {
            try {
                const response = await api.get('/dashboard/nbAthletesByCategory');

                setDataCategory(response.data)
                

            } catch (err) {
                catchErrorAPI(err);
            }
        };



        countAthlete();
        countAthleteByCategory();


    }, []);



    return (
        <Container>
            <Row><Col><h1>Statistiques</h1></Col></Row>
            <Row xs={1} md={1} lg={2}>
                <Col className="bloc">
                    <h3>Nombre de performance Homme / Femme</h3>
                    <Doughnut data={dataHF} />
                    <Table>
                        <tbody>
                            <tr>
                                <td>Nombre d'hommes</td>
                                <td>{nbHommes}</td>
                            </tr>
                            <tr>
                                <td>Nombre de femmes</td>
                                <td>{nbFemmes}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{nbHommes + nbFemmes}</td>
                            </tr>
                        </tbody>

                    </Table>
                </Col>
                <Col className="bloc">
                    <h2>Répartition catégorie Homme</h2>
                    <Doughnut data={dataCatH} />
                </Col>
                <Col className="bloc">
                    <h2>Répartition catégorie Femme</h2>
                    <Doughnut data={dataCatF} />
                </Col>
            </Row>
        </Container>

    )
}

export default Dashboard;