import React, { useEffect, useState, } from 'react'
import { api, catchErrorAPI } from "../../api/apiConfig";
import { Container, Row, Col, Spinner, Table, Button, Alert } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const UserList = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        email: ''
    });
    const [message, setMessage] = useState("");
    let navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Un prénom est requis'),
        last_name: Yup.string().required('Un nom est requis'),
        email: Yup.string()
        .required('Un email est requis')
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            'Veuillez fournir une adresse email valide'
        ),
    });

    useEffect(() => {

        fetch();


    }, [])

    const fetch = async () => {
        try {
            const response = await api.get('/users');

            setUsers(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const whiteList = async (values) => {
        try {
            const response = await api.post('/whitelist', 
                {first_name : values.first_name,
                    last_name : values.last_name, 
                    email : values.email});
            fetch();
            setMessage({error: false, msg: "Un mail a été envoyé à l'utilisateur"});
            setInitialValues({
                first_name: '',
                last_name: '',
                email: ''
            })
        } catch (error) {
            setMessage({error: true, msg: error.response.data});
        }


    }



    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }


    return (
        <Container>
            <Row>
                <Col>
                    <Table striped bordered hover responsive>
                        <thead>
                            <th>Prénom</th>
                            <th>Nom</th>
                            <th>Adresse mail</th>
                            <th>Validation</th>
                        </thead>
                        <tbody>
                            {
                                users.map((user, index) => {
                                    return (
                                        <tr>
                                            <td>{user.first_name}</td>
                                            <td>{user.last_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.whiteListProcess ? "Invitation envoyée" : "Enregistré"}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </Table>
                </Col>
            </Row>
            <Row>
                {message?.msg && (
                <Alert variant={ message?.error ? "danger" : "success"} dismissible onClose={ () => setMessage("")}>
                    {message?.msg}
                </Alert>)
                }
                </Row>
            <Row>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        whiteList(values);

                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form>
                            <label htmlFor="first_name">Prénom</label> <br />
                            <Field id="first_name" name="first_name" className="fieldTitleNews" /><br />

                            <label htmlFor="last_name">Nom</label> <br />
                            <Field id="last_name" name="last_name" className="fieldTitleNews" /><br />

                            <label htmlFor="email">Email</label> <br />
                            <Field id="email" name="email" className="fieldTitleNews" /><br />

                            <div>
                                <p><ErrorMessage name="first_name" /></p>
                                <p><ErrorMessage name="last_name" /></p>
                                <p><ErrorMessage name="email" /></p>
                            </div>

                            <Button variant="success" type="submit" className="mt-3" >
                                Inviter un nouvel utilisateur
                            </Button>

                        </Form>)}
                </Formik>
            </Row>
            <Row className='col-6'>
                <Button variant='secondary' className="mt-3"  onClick={() => {navigate("/admin/log")}} >Log</Button>
            </Row>
        </Container>
    )
}

export default UserList