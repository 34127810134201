import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Spinner, Form, Badge } from 'react-bootstrap'
import { api, catchErrorAPI } from '../../../api/apiConfig';
import { useParams } from 'react-router-dom';

const StageListRegistration = () => {
    const { idEvent } = useParams();
    const [registrations, setRegistrations] = useState(null);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [globalParticipants, setGlobalParticipants] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get(`/registrations/${idEvent}`);
            setRegistrations(response.data);

            const responseEvent = await api.get(`/event/${idEvent}`);
            setEvent(responseEvent.data);

            setGlobalParticipants(calculateNbParticipants(response.data));

            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    let calculateNbParticipants = (data) => {
        let nbAdults = 0;
        let nbKids = 0;

        let nbAdultsPaid = 0;
        let nbKidsPaid = 0;

        data.forEach(r => {
            if(r.status !== "canceled"){
                nbAdults += r.nbAdults;
                nbKids += r.nbKids;
            }
            if(r.status === "paid"){
                nbAdultsPaid += r.nbAdults;
                nbKidsPaid += r.nbKids;
            }
        });

        let total = nbKids + nbAdults;

        let totalPaid = nbKidsPaid + nbAdultsPaid ;

        return { nbAdults: nbAdults, nbKids: nbKids, total: total,
            nbAdultsPaid: nbAdultsPaid, nbKidsPaid: nbKidsPaid, totalPaid : totalPaid }
    }

    const changeStatus = async (registrationId, futurStatus) => {
        const putData = async () => {
            try {
                await api.put(`/registration/${registrationId}`, {
                    status: futurStatus
                });
                await fetchData();
            } catch (err) {
                setMessage({ error: true, msg: "Erreur à l'enregistrement" });
            }
        }
        putData();

    };

    const getBadgeVariant = (status) => {
        switch (status) {
            case 'registered':
                return 'warning';
            case 'paid':
                return 'success';
            case 'present':
                return 'primary';
            case 'canceled':
                return 'danger';
            default:
                return '';
        }
    };

    const getBadgeLabel = (status) => {
        switch (status) {
            case 'registered':
                return 'Inscrit';
            case 'paid':
                return 'Payé';
            case 'present':
                return 'Présent';
            case 'canceled':
                return 'Annulé';
            default:
                return '';
        }
    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }


    return (
        <Container>
            <Row className='py-3'>
                <h1 className="text-center">{event.label}</h1>
            </Row>
            <Row>
                <Col>
                    Inscrits : {globalParticipants.total} (Adultes : {globalParticipants.nbAdults}, Enfants : {globalParticipants.nbKids}) <br/>

                    Payés : {globalParticipants.totalPaid} (Adultes : {globalParticipants.nbAdultsPaid}, Enfants : {globalParticipants.nbKidsPaid})
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    {registrations.map((registration) => (
                        <Card key={registration._id} className='mt-1'>
                            <Card.Body>
                                <Card.Title>{registration.lastName} {registration.firstName}
                                    {' '}
                                    <Badge bg={getBadgeVariant(registration.status)}>{getBadgeLabel(registration.status)}</Badge>
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>{registration.nbKids > 0 ? <Badge bg="success">Mineur</Badge>: <Badge>Majeur</Badge>}</li>
                                        <li>Mail : {registration.emailAdress} </li>
                                        <li>Numéro athlete : {registration.numAthlete} </li>
                                        <li>Tuteurs : {registration.tutors}</li>
                                        <li>Numéro tuteur : {registration.tutorNumber}</li>
                                        <li>Remarques : {registration.note}</li>
                                    </ul>
                                </Card.Text>

                                <Form.Select onChange={(e) => changeStatus(registration._id, e.target.value)} value={registration.status}>
                                    <option value="registered" >Inscrit</option>
                                    <option value="paid" >Payé</option>
                                    <option value="present" >Présent</option>
                                    <option value="canceled" className="status-annule">Annulé</option>
                                </Form.Select>

                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>




        </Container >
    )
}

export default StageListRegistration