import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const GroupeItem = ({group}) => {
  return (
    <Container className='bloc p-3'>
                <Row className='mb-2'>
                  <h3 className="titleh2"><span className="jaune">{group.isEdj ? "École des jeunes - " : "Groupe "}{group.name}</span></h3>
                </Row>

                <Row><Col>{group.description}<hr /></Col></Row>
                <Row className='mb-2'>
                  {group.timetable.filter(item => item.hasTraining === true).map(day => {
                    return (
                      <div key={day.name} >
                        {day.name} : {day.startTime} <FontAwesomeIcon icon={faArrowRightLong} /> {day.endTime}
                      </div>
                    )
                  })}

                </Row>
                <Row xs={1} md={2} lg={3}> {group.trainers.map(coach => {
                  return (
                    <Col key={coach._id} className='mb-2'>
                      <Card className='cardTeam flex-grow-1' key={coach._id}>
                        {coach.imgUrl && <Card.Img variant="top" className='cardImgTeam rounded' src={coach.imgUrl} />}
                        <Card.Body className='cardTeamBody'>
                          <Card.Title className='titleTeamMember'>{coach.firstName} {coach.lastName}</Card.Title>
                          <Card.Text className='bodyTeam'>
                            <span>Entraineur</span>

                          </Card.Text>
                        </Card.Body>
                      </Card>

                    </Col>)
                })}
                </Row>
              </Container>
  )
}

export default GroupeItem