
import { useEffect } from "react";
import { Container, Row, Col, Image} from "react-bootstrap";
import LastResult from "../../components/Homepage/LastResult";
import LastNews from "../../components/Homepage/LastNews";
import Social from "../../components/Homepage/Social";
import InformationsHomePage from "../../components/Homepage/InformationsHomePage";
import CarouselComponent from "../../components/Inscription/CarouselComponent";



function HomePage(){

  useEffect(() => {

    document.title = "UAC Chatelineau";
    
  }, [])

  return(
    <Container>
        <Row id="info" className='pt-3' xs={1} md={2} lg={2}>
          <Col className="my-auto">
              <Image id="imageUAC" src="logo_uac.webp" className="img-fluid mx-auto d-block" alt="Logo de l'UAC" />
          </Col>
          <Col className='pt-3'>
              <CarouselComponent></CarouselComponent>

          </Col>
        </Row>
        <Row className='pt-3'>
            <Col>
                <InformationsHomePage></InformationsHomePage>
            </Col>
        </Row>
        <Row className='pt-3'>
          <Col>
               <LastNews></LastNews>
          </Col>
        </Row>
        <Row className='pt-3'>
            <Col>
                <LastResult></LastResult>
            </Col>
        </Row>
        <Row className='pt-3' xs={1} md={2} lg={2}>
            <Col>
                <Social></Social>
            </Col>
            <Col>
                <a href="https://www.instagram.com/uac.athletisme/" target="_blank" rel="noreferrer">
                    <Image id="imageInstagram" src="instagramImage.webp" className="img-fluid mx-auto d-block" alt="Photo du compte Instagram" />
                </a>
            </Col>
        </Row>

      </Container>
)}
export default HomePage;