import React from 'react'
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Modal, Nav, TabContainer, Spinner, Tab } from 'react-bootstrap'
import { api, catchErrorAPI } from '../../api/apiConfig';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as Yup from 'yup';
import { UserContext } from '../../Utils/userContext';
import GroupeItem from '../../components/Groupe/GroupeItem';

function GroupesPage() {
  const { userAuth } = useContext(UserContext);

  const [groups, setGroups] = useState(null);
  const [coachs, setCoachs] = useState([]);
  const [groupSelected, setGroupSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    isEdj: false,
    trainers: [],
    timetable: [
      { name: 'Lundi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Mardi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Mercredi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Jeudi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Vendredi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Samedi', hasTraining: false, startTime: '', endTime: '' },
      { name: 'Dimanche', hasTraining: false, startTime: '', endTime: '' },
    ],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Un nom de groupe est requis'),
    idOdoo: Yup.number().typeError('L\'identifiant Odoo doit être un nombre')
  });

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const handleCloseModalAdd = () => {
    fetchGroup();
    setInitialValues({
      name: '',
      description: '',
      isEdj: false,
      trainers: [],
      timetable: [
        { name: 'Lundi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Mardi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Mercredi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Jeudi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Vendredi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Samedi', hasTraining: false, startTime: '', endTime: '' },
        { name: 'Dimanche', hasTraining: false, startTime: '', endTime: '' },
      ],
      idOdoo: '',
    });
    setShowModalAdd(false);
  }

  const handleShowModalAdd = async (type) => {
    await fetchCoach();
    setTypeModal(type);
    setShowModalAdd(true);
  }

  const handleEditGroup = (group) => {

    let trainersIds = group.trainers.map(coach => coach._id);

    setInitialValues({
      name: group.name,
      description: group.description,
      isEdj: group.isEdj,
      trainers: trainersIds,
      timetable: group.timetable,
      idOdoo : group.idOdoo
    });

    setGroupSelected(group);

    handleShowModalAdd("edit");
  }


  useEffect(() => {
    document.title = "Nos groupes | UAC";

    fetchGroup();
  }, [showModalAdd]);

  const fetchGroup = async () => {

    try {
      const response = await api.get('/groups');
      setGroups(response.data);
      setLoading(false);
    } catch (err) {
      catchErrorAPI(err);
    }
  }

  const fetchCoach = async () => {

    try {
      const response = await api.get('/member/coach');
      setCoachs(response.data);
    } catch (err) {
      catchErrorAPI(err);
    }
  }

  const addGroup = (values) => {
    if (typeModal === "edit") {
      const putGroup = async () => {
        try {
          await api.put(`/group/${groupSelected._id}`, values);
          handleCloseModalAdd();
          //setMessage({ error: false, msg: "Groupe modifié !" });
        } catch (err) {
          //setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      putGroup();


    } else {
      const postGroup = async () => {
        try {
          await api.post(`/group`, values);
          //setMessage({ error: false, msg: "Groupe ajouté !" });
          fetchGroup();
        } catch (err) {
          //setMessage({ error: true, msg: "Erreur à l'enregistrement" });
          catchErrorAPI(err);
        }
      }
      postGroup();

      handleCloseModalAdd();
    }
  }

  const handleDeleteGroup = async (id) => {
    const deleteGroup = async () => {
      try {
        await api.delete(`/group/${id}`);
        fetchGroup();
      } catch (err) {
        catchErrorAPI(err);
      }
    }
    deleteGroup();
    handleCloseModalAdd();

  }

  const handleUp = async (group, index) => {

    let id1 = groups[index - 1]._id;
    let id2 = group._id;

    const swapGroup = async () => {
      try {
        await api.post(`/group/swap`, { id1: id1, id2: id2 });
        fetchGroup();
      } catch (err) {
        catchErrorAPI(err);
      }
    }
    swapGroup();
  }


  if (loading) {
    return <Spinner animation="border" role="status">
      <span className="visually-hidden">Chargement...</span>
    </Spinner>
  }


  return (
    <Container>
      <Row className="text-center py-3">
        <Col>
          <h1 className="titleh1"><span className="jauneTitre">Les groupes à l'UAC</span></h1>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          {userAuth &&
            <Button onClick={handleShowModalAdd}>Ajouter un groupe</Button>}
        </Col>
      </Row>

      <Row>
        <Col>
          <TabContainer id="groupe_tabContainer" defaultActiveKey="edj">

            <Nav variant="pills" className="flex-nowrap bloc p-3" id="NavGroup"  >
              <Nav.Item className='groupe_navitem' key="edj">
                <Nav.Link eventKey="edj" className='groupe_navLink'>École des jeunes (de 5 à 13 ans)</Nav.Link>
              </Nav.Item>
              <Nav.Item className='groupe_navitem' key="specific" >
                <Nav.Link eventKey="specific" className='groupe_navLink'>Groupes spécifiques (à partir de 14 ans)</Nav.Link>
              </Nav.Item>
            </Nav>
            <br />

            <Tab.Content>
              <Tab.Pane eventKey="edj">
                {groups && groups.length > 0 && (groups
                  .filter(g => g.isEdj === true)
                  .map((group, index) => {
                    return (
                      <Row key={group._id} id={group._id} >
                        <Col>
                          {userAuth &&
                            <div>

                              <FontAwesomeIcon icon={faPen} onClick={() => handleEditGroup(group)} />
                              <FontAwesomeIcon className='spaceIconeRight' icon={faCaretUp} onClick={() => handleUp(group, index)} />
                            </div>}
                          <GroupeItem group={group}></GroupeItem>
                        </Col>
                      </Row>)
                  }))}
              </Tab.Pane>
              <Tab.Pane eventKey="specific">

                <Container>
                  <Row className='bloc'>
                    <Nav id="NavGroup" variant="pills" className="flex-column flex-sm-row">
                      {groups && groups.length > 0 && (groups
                      .filter(g => g.isEdj === false)
                      .map(group => {
                        return (
                          <Nav.Item key={group._id}>
                            <Nav.Link href={`#${group._id}`}>
                              {group.name}
                            </Nav.Link>
                          </Nav.Item>
                        )
                      }))}
                    </Nav>
                  </Row>
                </Container>


                {groups && groups.length > 0 && (groups
                  .filter(g => g.isEdj === false)
                  .map((group, index) => {
                    return (
                      <Row key={group._id} id={group._id} >
                        <Col>
                          {userAuth &&
                            <div>

                              <FontAwesomeIcon icon={faPen} onClick={() => handleEditGroup(group)} />
                              <FontAwesomeIcon className='spaceIconeRight' icon={faCaretUp} onClick={() => handleUp(group, index)} />
                            </div>}
                          <GroupeItem group={group}></GroupeItem>
                        </Col>
                      </Row>)
                  }))}
              </Tab.Pane>
            </Tab.Content>
          </TabContainer>
        </Col>
      </Row>

      <Modal show={showModalAdd} onHide={handleCloseModalAdd} backdrop="static"
        keyboard={false} fullscreen={false} >
        <Modal.Header className='headerModal'>
          <Modal.Title className='titleModal'>

            {typeModal === "edit" ? <span>Modifier un groupe</span> : <span>Ajouter un groupe </span>}
          </Modal.Title>
          <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalAdd} />
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Container>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addGroup(values);
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <label htmlFor="name" className='titleForm'>Nom du groupe</label> <br />
                  <Field id="name" name="name" className="fieldTitleNews" /><br />

                  <label>
                    <Field type="checkbox" name="isEdj" id="isEdj" />
                    Groupe EDJ
                  </label> <br />


                  <label htmlFor="description" className='titleForm'>Description</label><br />
                  <Field id="description" name="description" as="textarea" component="textarea" className="fieldContentNews" /><br />

                  <div id="checkbox-group" className='titleForm'>Entraineur(s) du groupe</div>
                  <div role="group" aria-labelledby="checkbox-group">
                    {
                      coachs.map(coach => {
                        return (
                          <label className='labelCoachForm'>
                            <Field type="checkbox" name="trainers" value={coach._id} />
                            {coach.firstName} {coach.lastName}
                          </label>
                        )
                      })
                    }
                  </div>

                  <div id="checkbox-group" className='titleForm'>Horaires</div>

                  <FieldArray name="timetable">
                    {() => (
                      <>
                        {values.timetable.map((day, index) => (
                          <div key={index}>
                            <label>
                              <Field
                                type="checkbox"
                                name={`timetable[${index}].hasTraining`}
                              />
                              {day.name}
                            </label>
                            {day.hasTraining && (
                              <div>
                                <label>
                                  Début :
                                  <Field
                                    type="time"
                                    name={`timetable[${index}].startTime`}
                                  />
                                </label>
                                <label>
                                  Fin :
                                  <Field
                                    type="time"
                                    name={`timetable[${index}].endTime`}
                                  />
                                </label>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <label htmlFor="idOdoo" className='titleForm'>Id Odoo</label> <br />
                  <Field id="idOdoo" type="Number" name="idOdoo" className="fieldTitleNews" /><br />


                  <div>
                    <p><ErrorMessage name="titre" /></p>
                    <p><ErrorMessage name="auteur" /></p>
                    <p><ErrorMessage name="type" /></p>
                  </div>


                  {typeModal !== "edit" ? <Button variant="primary" type="submit" className="mt-3">Ajouter
                  </Button> : <div>

                    <Button variant="light" className="mt-3 me-2" onClick={() => handleDeleteGroup(groupSelected._id)}>
                      Supprimer
                    </Button>
                    <Button variant="success" type="submit" className="mt-3" >
                      Modifier
                    </Button>
                  </div>}
                </Form>)}
            </Formik>



          </Container>
        </Modal.Body>
      </Modal>

    </Container>
  )
}

export default GroupesPage