import {Table, Container, Button, Col, Row, Spinner} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { cacheExpiration } from "../Utils/utilis";
import { api, catchErrorAPI } from "../api/apiConfig";




function TabGroup() {
    let navigate = useNavigate();

    const { idGroupe } = useParams();

    const [athletes, setAthletes] = useState();
    const [groupe, setGroupe] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //document.title = groupe.name + " |
        fetchData();

    }, [idGroupe])

    const fetchData = async () => {
        try {
            const groupeData = await api.get(`/group/${idGroupe}`);
            const athletesData = await api.get(`/athletes/groupes/${idGroupe}`);

            setGroupe(groupeData.data);
            setAthletes(athletesData.data);

            setLoading(false);

        } catch (err) {
            catchErrorAPI(err);
        }
    }

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }

    return (
        <Container>
            <Row>
                <Col className="px-0">
                    <h1>{groupe.name}</h1>
                    <Table id="tabGroupe" striped bordered hover>
                        <thead>
                            <tr>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Fiche</th>
                            </tr>
                        </thead>
                        {
                            athletes.map((athlete => {
                                return (
                                    <tbody key={athlete._id}>
                                        <tr>
                                            <td>{athlete.prenom}</td>
                                            <td>{athlete.nom}</td>
                                            <td><Button className="buttonJaune" size="sm" onClick={() => { navigate(`/athlete/${athlete._id}`) }}>Fiche</Button></td>
                                        </tr>
                                    </tbody>
                                )
                            }))
                        }
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default TabGroup;