import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BancontactQRCode from '../../../components/Event/PaymentQRCode';
import { api, catchErrorAPI } from '../../../api/apiConfig';
import { useParams } from 'react-router-dom';

const EventConfirmationPage = () => {
    const { idConfirmation } = useParams();
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        document.title = "Confirmation | UAC";
    
        const fetchData = async () => {
          try{
            const response = await api.get(`/registration/${idConfirmation}`);
            setRegistration(response.data);
            let totalPrice = response.data.nbAdults * response.data.idEvent.generalPrice +  response.data.nbVege * response.data.idEvent.generalPrice
                        + response.data.nbKids * response.data.idEvent.kidPrice;

            setTotalPrice(totalPrice);

            setLoading(false);
          }catch(err){
            catchErrorAPI(err);
          }
        }
        fetchData();
      }, []);
  
  
  
    if (loading) {
      return (
        <Container className="bloc">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
          </Spinner>
        </Container>
      )
    }
  

    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Confirmation</span></h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Merci pour votre inscription</span></h3><br />
                </Col>
            </Row>

            <Row>
                <Col className='mx-2 bloc'>

                    <BancontactQRCode
                        montant={totalPrice}
                        lastName={registration.lastName}
                        firstName={registration.firstName}
                        eventName={registration.idEvent.label}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default EventConfirmationPage