import {Container, Row, Nav, Col, Spinner} from "react-bootstrap";
import TabGroup from "../../components/TabGroup";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
    Routes,
    Route,
    useNavigate
  } from "react-router-dom";
import {api, catchErrorAPI} from "../../api/apiConfig";


function GroupePage(){
    let navigate = useNavigate();
    const group = useParams()['*'];
    const [loading, setLoading] = useState(true);
    const [listGroups, setListGroups] = useState();

    const [activeKey, setActiveKey] = useState('/groupe/647143af2f9a8ead9968b46d');

    useEffect(() => {
        fetchGroupes();
        if (group && group !== undefined) {
            setActiveKey(`/groupe/${group}`);
        }
    }, [group]);

    const fetchGroupes = async () => {

        try {
            const response = await api.get('/groupsList');
            setListGroups(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
        </Spinner>
    }




    return(
    <Container>
        <Row className="text-center py-3">
             <h1 className="titleh1"><span className="jauneTitre">Liste des athlètes</span></h1> 
        </Row>
        <Row>
            <Col>
            <Container>
                <Row className="bloc">
                    <Nav id="NavGroup" variant="pills"  activeKey={activeKey} onSelect={(key) => {
                    setActiveKey(key);
                    navigate(key);
                    }} className="flex-column flex-sm-row">
                        {listGroups.map(groupe => { return (
                            <Nav.Item  key={`/groupe/${groupe._id}`}>
                                <Nav.Link 
                                    eventKey={`/groupe/${groupe._id}`} >
                                        Groupe {groupe.name}
                                </Nav.Link>
                            </Nav.Item>
                        )})}
                    </Nav>
                </Row>
                <Row className='py-3 bloc' >
                    <Routes>
                        <Route path=':idGroupe' element={<TabGroup />} />
                    </Routes>
                </Row>
            </Container>
            </Col>
        </Row>
    </Container>
)}

export default GroupePage;