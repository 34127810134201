import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CarouselComponent() {
  return (
    <Carousel>
      <Carousel.Item interval={2000} >
        <img
          className="d-block w-100 carousel-image"
          src="carrousel/group1.webp"
          alt="groupe1"
          rel="preload"
        />
      </Carousel.Item>
        <Carousel.Item interval={2000}>
            <img
                className="d-block w-100 carousel-image"
                src="carrousel/group2.webp"
                alt="groupe2"
            />
        </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 carousel-image"
          src="carrousel/group3.webp"
          alt="groupe3"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 carousel-image"
          src="carrousel/group4.webp"
          alt="groupe4"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default CarouselComponent;
