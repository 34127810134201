import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ContactForm from "../../components/ContactForm";

function ContactPage() {
    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Contact</span></h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <ContactForm></ContactForm>
                </Col>
            </Row>

        </Container>
    )
}

export default ContactPage