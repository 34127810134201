import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Spinner, Button } from 'react-bootstrap'
import { api, catchErrorAPI } from '../../api/apiConfig';
import { useNavigate } from 'react-router-dom';

const GestionEventPage = () => {

    let navigate = useNavigate();
    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {


        const fetchData = async () => {
            try {
                const response = await api.get(`/events`);
                setEvents(response.data);


                setLoading(false);
            } catch (err) {
                catchErrorAPI(err);
            }
        }
        fetchData();
    }, []);

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Col>
                    {events.map((event) => (
                        <Card key={event._id} className='mt-2'>
                            <Card.Body>
                                <Card.Title>{event.label}</Card.Title>
                                {event.type === "event" ? (
                                    <Button onClick={() => navigate(`/admin/eventRegistration/${event._id}`)}>
                                        Voir Détails
                                    </Button>
                                ) : event.type === "competition" ? (
                                    <Button onClick={() => navigate(`/admin/compRegistration/${event._id}`)}>
                                        Voir Détails
                                    </Button>
                                ) : (
                                    <Button onClick={() => navigate(`/admin/stageRegistration/${event._id}`)}>
                                        Voir Détails
                                    </Button>
                                )}
                            </Card.Body>
                        </Card>

                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default GestionEventPage