import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const RGPDPage = () => {
    return (
        <Container>
            <Row className='bloc'>
                <Col>
                    <div>
                        <h1>Politique de Confidentialité</h1>
                        <p><em>Dernière mise à jour : 15/08/2023</em></p>
                        <p>Nous vous remercions de visiter notre site concernant l'Union Athlétique Chatelineau. La protection de vos données personnelles est une priorité pour nous. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations que vous nous fournissez.</p>
                    </div>

                    <div>
                        <h2>Collecte et Utilisation des Informations</h2>
                        <p>Nous collectons des informations publiques provenant d'autres sites web pour les regrouper et les afficher sur notre site d'athlétisme. Ces informations sont collectées dans un but informatif et pour promouvoir les événements et les actualités liés à l'athlétisme.</p>
                    </div>

                    <div>
                        <h2>Stockage des Données</h2>
                        <p>Vos données personnelles sont stockées sur des serveurs situés en Europe, conformément aux réglementations du RGPD. Nous avons pris des mesures pour choisir des partenaires d'hébergement qui respectent les normes de sécurité et de confidentialité en vigueur dans l'Union européenne.</p>
                    </div>

                    <div>
                        <h2>Base Légale du Traitement</h2>
                        <p>Nous souhaitons vous informer que nous traitons des données personnelles de manière légale et légitime en se basant sur les raisons suivantes :</p>

                        <p>
                            Droit à l'Image : Les athlètes participant à nos événements et activités ont accepté le droit à l'image conformément aux réglementations en vigueur. En participant à ces événements, ils reconnaissent et acceptent que des photographies et des vidéos puissent être prises et utilisées à des fins promotionnelles et informatives liées à notre club d'athlétisme.
                        </p>

                        <p>
                            Données Publiques de la Ligue d'Athlétisme Belge : Les informations concernant les athlètes qui ont accepté d'être membres de la ligue d'athlétisme belge sont considérées comme des données publiques, conformément aux dispositions réglementaires de la ligue d'athlétisme belge. Ces données sont collectées et traitées dans le cadre de la promotion de notre club d'athlétisme et de la participation à des compétitions. Le traitement des données par la ligue d'athlétisme belge est couvert par l'article 35 du règlement de la ligue, qui établit les mesures et les garanties pour la protection des données personnelles conformément aux principes du RGPD.
                        </p>

                        <p>
                            Collecte et Consentement des Informations : Lors de l'inscription à nos événements et activités, nous sollicitons votre consentement explicite pour la collecte, le traitement et l'utilisation de vos données personnelles. Votre participation à nos événements implique votre accord pour que nous puissions recueillir les informations nécessaires à des fins d'organisation, de communication et de suivi de votre parcours au sein de notre club d'athlétisme. Vous avez le droit de retirer votre consentement à tout moment en nous contactant à l'adresse indiquée dans la section "Contact".
                        </p>



                    </div>

                    <div>
                        <h2>Finalités du Traitement</h2>
                        <p>
                            Nous souhaitons vous rassurer quant à l'utilisation de vos données personnelles. Les informations que nous collectons sont traitées dans un cadre précis et ne sont pas partagées avec d'autres organisations. Nous utilisons vos données exclusivement dans le but de fournir des informations pertinentes et utiles concernant notre club d'athlétisme et ses activités.
                        </p>
                        <p>
                            Plus spécifiquement, nous utilisons vos données personnelles pour les finalités suivantes :
                        </p>
                        <p>
                            1. Informer sur les Résultats du Club : Nous utilisons vos données pour vous tenir informé(e) des résultats et des performances de notre club d'athlétisme, notamment en ce qui concerne les compétitions, les événements et les réalisations sportives.
                        </p>
                        <p>
                            2. Création d'un Top 10 du Club : Vos données contribuent à l'élaboration du classement des dix meilleurs athlètes de notre club, basé sur leurs performances dans diverses disciplines athlétiques. Ce classement interne nous permet de reconnaître et de célébrer les réalisations exceptionnelles au sein de notre communauté sportive.
                        </p>
                        <p>
                            3. Établissement du Top 5 de la Saison : Nous utilisons également vos données pour établir un classement des cinq athlètes les plus performants de la saison en cours. Cette reconnaissance est destinée à encourager l'excellence sportive au sein de notre club.
                        </p>
                        <p>
                            4. Inventaire des Membres du Club : Vos données personnelles sont utilisées pour maintenir un inventaire précis et à jour de nos membres. Cela nous permet de gérer efficacement notre club, de communiquer avec vous et de garantir que vous avez accès à toutes les opportunités et les informations liées à votre participation.
                        </p>

                    </div>

                    <div>
                        <h2>Durée de Conservation</h2>
                        <p>Vos données personnelles seront conservées pendant 20 ans ou jusqu'à ce que vous retiriez votre consentement.</p>
                    </div>

                    <div>
                        <h2>Utilisation de Cookies</h2>
                        <p>Nous tenons à vous informer que nous n'utilisons pas de cookies ni de technologies similaires sur notre site. Vous pouvez parcourir nos pages en toute tranquillité, sachant que votre expérience de navigation n'est pas influencée par la présence de cookies.</p>
                    </div>


                    <div>
                        <h2>Droits des Utilisateurs</h2>
                        <p>Conformément au RGPD, vous avez le droit de :</p>
                        <ul>
                            <li>Accéder à vos données personnelles.</li>
                            <li>Rectifier les données inexactes.</li>
                            <li>Supprimer vos données dans certaines circonstances.</li>
                            <li>Limiter le traitement de vos données.</li>
                            <li>Obtenir une copie de vos données dans un format portable.</li>
                            <li>Vous opposer au traitement de vos données.</li>
                        </ul>
                        <p>
                            Pour exercer ces droits, veuillez nous contacter à <a href="mailto:stafftechniqueuac@gmail.com">stafftechniqueuac@gmail.com</a>.
                        </p>
                    </div>

                    <div>
                        <h2>Droit de Retrait du Consentement</h2>
                        <p>Vous pouvez retirer votre consentement à tout moment en nous contactant à <a href="mailto:stafftechniqueuac@gmail.com">stafftechniqueuac@gmail.com</a>.</p>
                    </div>

                    <div>
                        <h2>Prise de Décision Automatisée</h2>
                        <p>Nous ne prenons de décisions automatisées basées sur vos données personnelles.</p>
                    </div>

                    <div>
                        <h2>Mises à Jour et Modifications</h2>
                        <p>Nous pouvons mettre à jour cette politique de confidentialité périodiquement pour refléter les changements dans nos pratiques ou la réglementation. La date de la dernière mise à jour sera indiquée en haut de la page.</p>
                    </div>

                    <div>
                        <h2>Contactez-Nous</h2>
                        <p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à <a href="mailto:stafftechniqueuac@gmail.com">stafftechniqueuac@gmail.com</a>.</p>
                    </div>

                </Col>
            </Row>
        </Container>
    )
}

export default RGPDPage