import React, { useEffect, useState, } from 'react'
import { api, catchErrorAPI } from "../../api/apiConfig";
import { Container, Row, Col, Spinner, Table, Button, Alert } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const ListAthletesArcived = () => {
    const [loading, setLoading] = useState(true);
    const [athletes, setAthetes] = useState([]);
    let navigate = useNavigate();
    const [message, setMessage] = useState("");


    useEffect(() => {

        fetchData();

    }, [])

    const fetchData = async () => {
        try {
            const response = await api.get('/athletes/archived/all');

            setAthetes(response.data);
            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }

    const unarchiveAthlete = async (athleteId) => {
        try {
            await api.put(`/athletes/${athleteId}`,{archived : false});

            fetchData();
        } catch (err) {
            catchErrorAPI(err);
        }
    }



    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }


    return (
        <Container>
            <Row>
                {message?.msg && (
                    <Alert variant={ message?.error ? "danger" : "success"} dismissible onClose={ () => setMessage("")}>
                        {message?.msg}
                    </Alert>)
                }
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover responsive>
                        <thead>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Fiche</th>
                        <th>Dé-archiver</th>
                        </thead>
                        <tbody>
                        {
                            athletes.map((athlete, index) => {
                                return (
                                    <tr>
                                        <td>{athlete.prenom}</td>
                                        <td>{athlete.nom}</td>
                                        <td><Button onClick={() => {navigate(`/athlete/${athlete._id}`)}}>Fiche</Button></td>
                                        <td><Button onClick={() => {unarchiveAthlete(athlete._id)}}>Dé-archiver</Button></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>

                    </Table>
                </Col>
            </Row>

        </Container>
    )
}

export default ListAthletesArcived