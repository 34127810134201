export const getPerformanceString = (performance, type) => {

    if (type === "distance") {
        return performance + " m";
    }

    if(type === "points"){
        return performance;
    }

    let restant = performance;
    let x = 0;
    let minute = 0;
    let seconde = 0;
    let dixieme = 0;

    x = restant / 60000;
    minute = Math.trunc(x);
    restant = restant - (minute * 60000);
    x = restant / 1000;
    seconde = Math.trunc(x);
    restant = restant - (seconde * 1000);
    x = restant / 10;
    dixieme = Math.trunc(x);

    if (minute !== 0) {
        return minute + ":" + twoNumber(seconde) + "." + twoNumber(dixieme);
    } else {
        return seconde + "." + twoNumber(dixieme);
    }
}

const twoNumber = (number) => {
    return (number < 10) ? '0' + number.toString() : number.toString();
}

export const getButtonColor = (saison) => {
    if (saison === "Indoor" || saison === "indoor") {
        return "primary";
    } else {
        if (saison === "Outdoor" || saison === "outdoor") {
            return "info";
        }
        return "warning";
    }
}

export const getWindSpan = (windSpeed) => {
    if (parseFloat(windSpeed) > 2) {
        return "windspeedNOK";
    }
    else{
        if(windSpeed < 0){
            return "windspeedPositif";
        } else{
            return "windspeedNegatif";
        }

    }
}

export const getInitial = (prenom, nom) => {
    return prenom + " " + nom[0] + ".";
}

export const getSpeed = (distance, temps) => {
    let kmh = (distance / temps) * 3600;
    let arrondi = (Math.round(kmh * 100) / 100).toFixed(2);

    return arrondi
}

export const getFirstCapitalString = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const cacheExpiration = 30 * 60 * 1000;

export const getTabEpreuvesTrie = (tab, saison) => {
    let epreuves = [];

    if (saison === "Indoor") {
        epreuves = window.listeEpreuvesIndoor;
    } else {
        epreuves = window.listeEpreuvesOutdoor;
    }
    let tabTrie = new Array(epreuves.length);

    for (let i = 0; i < tab.length; i++) {
        let epreuveSelect = tab[i].epreuve;
        
        let indexTrie = epreuves.findIndex(e => e.label === epreuveSelect);
        tabTrie[indexTrie] = tab[i];
    }

    return tabTrie
}


export const getLabelCat = (cat, sexe) => {
    if(cat === "Cadets" && sexe === "Femmes"){
        return "Cadettes"
    }
    if(cat === "Benjamins" && sexe === "Femmes"){
        return "Benjamines"
    }
    if(cat === "TC"){
        return "Toutes Catégories"
    }
    return cat;

};
